import React from "react";
import "./styles.css";

const SvgIcon = ({ src, width, height, className, alt, ...props }) => (
  <img
    className={`${className || ""} svg-image`}
    src={`/img/svg/${src}`}
    with={width}
    height={height}
    alt={alt || "svg"}
    {...props}
  />
);

export default SvgIcon;
