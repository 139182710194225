import { FacebookOutlined, GoogleOutlined } from "@ant-design/icons";
import { Form, Input, Button, Select, Popover, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Option } from "antd/lib/mentions";
import { useHistory } from "react-router";
import Checkbox from "antd/lib/checkbox/Checkbox";
import PopUpTerm from "../../../components/PopUpTerm";
import { clearUser, createUser } from "../../../Redux/User/action";
import { connect } from "react-redux";
import { clearArtist, createArtist } from "../../../Redux/Artist/action";
import { ToastContainer, toast } from "react-toastify";
import * as S from "../styles";
import store from "../../../Redux/store";
import { openRegistrationModal } from "../../../Redux";
import constants from "../../../constants";
import { useForm } from "antd/lib/form/Form";

const passwordValidator = require("password-validator");

const termsAndCondition = {
  title: "Terms And Condition",
  content: (
    <div classnamename="WordSection1">
      <p classname="msonormal" style={{ textAlign: "justify" }}>
        Dear <b>User</b>,
      </p>
      <p classname="MsoNormal" style={{ textAlign: "justify" }}>
        Please read and agree to the following terms and conditions, if you wish
        to be eligible to participate in the <b>Closed Beta Testing</b>.{" "}
      </p>
      <p classname="MsoNormal" style={{ textAlign: "justify" }}>
        BY SELECTING THE “ACCEPT” BUTTON, YOU ACKNOWLEDGE THAT:{" "}
      </p>
      <p classname="MsoNormal" style={{ textAlign: "justify" }}>
        (1) YOU ARE 18 YEARS OF AGE OR OLDER; AND{" "}
      </p>
      <p classname="MsoNormal" style={{ textAlign: "justify" }}>
        (2) YOU HAVE READ, UNDERSTOOD, AND ACCEPTED THE TERMS AND CONDITIONS OF
        THIS AGREEMENT.
      </p>
      <p classname="MsoNormal" style={{ textAlign: "justify" }}>
        This BETA TEST Agreement (“<b>Agreement</b>”) is entered into between{" "}
        <b>KalaCube</b>
        <b>™</b> and the <b>User</b>, and it is understood between the parties
        THAT:
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        1.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        The proposed <b>KalaCube</b> website (www.KalaCube.com) and its proposed
        Mobile Phone App (collectively and/ or severally, referred to as “
        <b>PLATFORM</b>”) are in development phase / Beta Testing Phase and have
        not been released to the public. The present Agreement shall continue to
        apply to all updates, changes, modifications to the PLATFORM, unless any
        term/s is/ are expressly withdrawn and / or modified, at the sole
        discretion of KalaCube.{" "}
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        2.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        The <b>PLATFORM</b> covered in this Agreement as a pre-release copy and
        all affiliated materials, including documentation, images, videos, all
        software components and information (collectively the “Product”), is
        subject to copyright vested with KalaCube. Scope of this agreement is
        the licensing (not selling) of the “PLATFORM” to the User, as the ‘User’
        (either an individual or an entity). KalaCube reserves all rights not
        expressly granted. The Agreement maybe subject to rights of other “
        <b>associated third parties</b>”, including but not limited to other
        Users using the PLATFORM, and the User shall be liable to respect and to
        not violate all such rights.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        3.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <b>Ownership and Copyright</b> of PLATFORM Title to the PLATFORM and all
        copies thereof remain with KalaCube. The PLATFORM is protected by Indian
        copyright laws and international treaty provisions. User will not remove
        copyright notices from the Software. User agrees to prevent any
        unauthorized copying of the Software. Except as expressly provided
        herein, KalaCube does not grant any express or implied right to User
        under KalaCube patents, copyrights, trademarks, or trade secret
        information.
      </p>
      <p
        classname="MsoListParagraph"
        style={{ textAlign: "justify", lineHeight: "150%" }}
      >
        This Clause shall continue to bind the User even after the completion of
        Beta Testing and / or termination of the Agreement.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        4.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <b>Limited License.</b> User is entitled to access, download or install,
        and operate the PLATFORM solely for the purposes of performing
        obligations under this Agreement. User cannot sell, license, or transfer
        the PLATFORM, or reproductions of the PLATFORM to other parties in any
        way. User may download or Install, and operate the PLATFORM on a
        compatible computer device (including Tablet/s / Mobile Phone/s) linked
        to the email address provided on sign-up. There is no remuneration
        assured by KalaCube to its Users in any manner for the use of the
        PLATFORM. However, KalaCube reserves rights to all and any remuneration,
        profit, rewards, income, etc. that may accrue up-on use of the PLATFORM,
        unless otherwise agreed with the User in writing.{" "}
      </p>
      <p
        classname="MsoListParagraph"
        style={{ textAlign: "justify", lineHeight: "150%" }}
      >
        This Clause shall continue to bind the User even after the completion of
        Beta Testing and / or termination of the Agreement.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        5.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <b>Limitation on Liability.</b> Provision of any PLATFORM under this
        Agreement is experimental and shall not create any obligation for
        KalaCube to continue to develop, productize, support, repair, offer for
        sale or in any other way continue to provide or develop PLATFORM either
        to User or to any other party. The PLATFORM is provided “AS IS” without
        any express or implied warranty of any kind including warranties of
        merchantability or fitness for any particular purpose. In no event shall
        KalaCube or its representatives, agents or suppliers be liable for any
        damages whatsoever (including, without limitation, damages for loss of
        profits, business interruption, loss of information) arising out of the
        use of or inability to use the PLATFORM, even if KalaCube has been
        advised of the possibility of such damages.
      </p>
      <p
        classname="MsoListParagraph"
        style={{ textAlign: "justify", lineHeight: "150%" }}
      >
        This Clause shall continue to bind the User even after the completion of
        Beta Testing and / or termination of the Agreement.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        6.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        During the participation of the User in Beta Testing of the PLATFORM,
        KalaCube is not obligated to provide any maintenance, technical or other
        support for the pre-release PLATFORM. Since the PLATFORM is under Beta
        Testing, KalaCube cannot and does not assure integrity or correctness of
        data / material in the PLATFORM and is absolved of any/ all liability/
        responsibility under any law for the time being in force for any loss or
        any consequence/s arising out of the use of the PLATFORM, to the
        User.&nbsp;{" "}
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        7.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        The User acknowledges that KalaCube has no express or implied obligation
        to announce or make available a commercial version of the PLATFORM to
        anyone in the future. Should a commercial version be made available, it
        may have features or functionality that are different from those found
        in the PLATFORM.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        8.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <b>Termination of Agreement.</b> User’s rights under the Agreement will
        terminate upon –
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        a.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        The initial commercial release by KalaCube by generally available
        version of the PLATFORM, or{" "}
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        b.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Upon breach of any of the terms of the Agreement by the User, or
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        c.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Upon breach of any law for the time being in force in India or under any
        applicable international treaty provision, by the User, or
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        d.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Upon violation of any rights (
        <i>contractual or otherwise, whether known to the User or not</i>) of
        other associated third parties of KalaCube, or
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        e.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Upon unilateral termination by KalaCube.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: ".75in",
          textAlign: "justify",
          lineHeight: "150%",
        }}
      >
        Unless, otherwise expressly stated in the Agreement, upon any expiration
        or termination of this Agreement, the rights and licenses granted to the
        User under this Agreement shall immediately terminate, and the User
        shall immediately cease using, and will return to KalaCube (or, at
        KalaCube’s request, destroy), the Beta Software, Documentation, and all
        other tangible items in User’s possession or control that are
        proprietary to KalaCube or its associated third parties or contain
        Confidential Information pertaining to KalaCube or its PLATFORM or its
        associated third parties. All licenses in favour of the User shall
        terminate upon termination of the Agreement and the User shall have no
        claims whatsoever against KalaCube after the termination.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        9.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <b>Duties of the User</b>. The User agrees to report any flaws, errors
        or imperfections discovered in PLATFORM or other materials where User
        has been granted access to the Beta Test. User understands that prompt
        and accurate reporting is the purpose of the Beta Tests and undertakes
        to use best efforts to provide frequent reports on all aspects of the
        product both positive and negative and acknowledges that any
        suggestions, feedback, improvements, modifications and changes arising
        from or in connection with the User’s feedback/ contribution to the
        KalaCube’s Project, shall remain and become the exclusive property of
        KalaCube. The User shall carry out such duties without any consideration
        from KalaCube as it is understood that the Beta Testing may eventually
        aid the User’s economic interests after commercial release of the
        PLATFORM.{" "}
      </p>
      <p
        classname="MsoListParagraph"
        cxspmiddle
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        10.
        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
        <b>Non-Disclosure</b>. The User will not disclose any information
        related to the PLATFORM (features, code, architecture, etc.), or its
        testing (bugs, crashes, performance, etc.) or any feedback under Clause
        7 above, regarding PLATFORM to any third party without the prior written
        approval of KalaCube. The User will maintain the confidentiality of
        PLATFORM with at least the same degree of care that User would use to
        protect its own confidential and proprietary information, but not less
        than a reasonable degree of care under the circumstances.{" "}
      </p>
      <p
        classname="MsoListParagraph"
        cxspmiddle
        style={{ textAlign: "justify", lineHeight: "150%" }}
      >
        The User understands and acknowledges that any breach of this clause can
        cause irreparable damage to KalaCube to which KalaCube would be entitled
        to injunctive relief and/ or equitable relief, including but not limited
        to exemplary damages, against the User.
      </p>
      <p
        classname="MsoListParagraph"
        cxspmiddle
        style={{ textAlign: "justify", lineHeight: "150%" }}
      >
        The User will not be liable for the disclosure of any confidential
        information which is:
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        a.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        in the public domain other than by a breach of this Agreement on User’s
        part; or{" "}
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        b.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        rightfully received from a third party without any obligation of
        confidentiality; or{" "}
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        c.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        rightfully known to User without any limitation on use or disclosure
        prior to its receipt from KalaCube; or{" "}
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        d.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        generally made available to third parties by KalaCube without
        restriction on disclosure.
      </p>
      <p
        classname="MsoListParagraph"
        style={{ textAlign: "justify", lineHeight: "150%" }}
      >
        This Clause shall continue to bind the User even after the completion of
        Beta Testing and / or termination of the Agreement.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        11.
        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
        <b>Privacy Policy</b>. KalaCube’s Privacy Policy (Privacy Policy)
        applies to the PLATFORM. User acknowledge and agrees that by using the
        PLATFORM, KalaCube may receive certain information about the User,
        including personally identifiable information, information that may be
        proprietary to the User and the User hereby consents to KalaCube’s
        collection, use and disclosure such information in accordance with the
        Privacy Policy.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        <b>
          12.
          <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
        </b>
        <b>Terms of Service</b>
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        a.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        The User understands and acknowledges that use of PLATFORM involves and
        entails use by KalaCube of information of the User, including personally
        identifiable information, information that may be proprietary to the
        User and the User hereby consents to KalaCube’s responsible use of the
        same. User also understands and acknowledges that any misuse of such
        information (personal/ proprietary) of the User by a third party shall
        not be binding on KalaCube and the User shall have no claims whatsoever
        for such misuse by third party, against KalaCube.
      </p>
      <p
        classname="MsoListParagraph"
        cxspmiddle
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        b.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        There are no fees for User’s use of the PLATFORM under this Agreement.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          lineHeight: "150%",
        }}
      >
        User is responsible for all costs and expenses associated with the use
        of the PLATFORM and the performance of all testing and evaluation
        activities, and shall not be entitled to any remuneration or
        reimbursement under this Agreement.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        c.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        If during the operation of the Agreement, any of the artworks published
        or displayed on the PLATFORM of KalaCube are sold using the PLATFORM, -
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.5in",
          textAlign: "justify",
          textIndent: "-1.5in",
          lineHeight: "150%",
        }}
      >
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        i.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        The User shall be solely responsible for ensuring the physical delivery
        of the same to the Purchaser. KalaCube may only connect the User with
        the willing Purchaser and may be liable to only share such details of
        the willing Purchaser with the User, as may be provided by the Purchaser
        and as may be necessary to facilitate the delivery. KalaCube may provide
        a designated logistic associate for the purpose of carrying out such
        delivery which will be done as per the terms applicable to such logistic
        service provider.{" "}
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.5in",
          textAlign: "justify",
          textIndent: "-1.5in",
          lineHeight: "150%",
        }}
      >
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        ii.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        The User shall be liable to pay to KalaCube, 10% of the price received
        by the User for any sale of artwork through use of KalaCube PLATFORM.
        This percentage is only an initial discounted offer and KalaCube may at
        its sole discretion increase such percentage payable to KalaCube.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          marginLeft: "1.5in",
          textAlign: "justify",
          textIndent: "-1.5in",
          lineHeight: "150%",
        }}
      >
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        iii.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        The User understands, acknowledges, and accepts that KalaCube does not
        ensure any commercial benefits to the User during or after the operation
        of the Agreement.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        13.
        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
        This is the entire agreement between the parties relating to the subject
        matter hereof and all other terms are rejected. No waiver or
        modification of this Agreement shall be valid unless in writing. The
        waiver of a breach of any term hereof shall in no way be construed as a
        waiver of any term or other breach hereof. If any provision of this
        Agreement is held by a court of competent jurisdiction to be contrary to
        law the remaining provisions of this Agreement shall remain in full
        force and effect.
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        14.
        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
        Any legal action or proceeding arising under this Agreement will be
        governed by exclusively by the laws in force in India and will be
        brought exclusively in the courts located in Dehradun, India and the
        parties hereby consent to Jurisdiction and venue at Dehradun, India,
        without giving effect to the principles of conflict of law rules of any
        jurisdiction or the United Nations Convention on Contracts for the
        International Sale of Goods, the application of which is expressly
        excluded.{" "}
      </p>
      <p
        classname="MsoListParagraph"
        style={{
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
        }}
      >
        15.
        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
        This Agreement is personal to User. User shall not assign or otherwise
        transfer any rights or obligations under this Agreement.
      </p>
      <p
        classname="MsoNormal"
        style={{ textAlign: "justify", lineHeight: "150%" }}
      >
        The Agreement shall remain binding upon acceptance thereof and any use
        of the PLATFORM shall not be permissible unless the User agrees to this
        Agreement.{" "}
      </p>
    </div>
  ),
};

const SignUpForm = ({
  user,
  artist,
  createUserRecord,
  createArtistRecord,
  clearArtistRecord,
  clearUserRecord,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [loading, setLoading] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [userDetails, setUserDetails] = React.useState({});

  const history = useHistory();

  const navTo = (link) => {
    history.push(link);
  };

  const title = <strong>Password Policy</strong>;
  const passwordPolicyContent = (
    <React.Fragment>
      <span>
        <strong> Your password should contain:</strong>
      </span>
      <ul>
        <li>Minimum length of 8 characters</li>
        <li>Numerical characters (0-9)</li>
        <li>Special characters</li>
        <li>Uppercase letter</li>
        <li>Lowercase letter</li>
      </ul>
    </React.Fragment>
  );

  const validateToNextPassword = (rule, value, callback) => {
    const validationRulesErrors = schema.validate(value, { list: true });

    if (validationRulesErrors.length > 0) {
      callback(formatPasswordValidateError(validationRulesErrors));
    }
    callback();
  };

  const formatPasswordValidateError = (errors) => {
    for (const error of errors) {
      if (error === "min") {
        return "password length should be a at least 8 characters";
      } else if (error === "lowercase") {
        return "password should contain lowercase letters";
      } else if (error === "uppercase") {
        return "password should contain uppercase letters";
      } else if (error === "digits") {
        return "password should contain digits";
      } else if (error === "symbols") {
        return "password should contain symbols";
      }
    }
  };

  const schema = new passwordValidator();
  schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      setUserDetails(values);

      const userForm = {
        userName: values.username.toLowerCase(),
        email: values.email.toLowerCase(),
        phone: values.prefix + values.phone,
        role: "artist",
      };
      createUserRecordInTable(userForm);
    } catch (e) {
      setLoading(false);
      toast.error(e.massage, { position: "top-right" });
    }
  };
  const createUserRecordInTable = async (data) => {
    createUserRecord(data);
  };

  useEffect(() => {
    if (artist.success && artist.success === "succesful") {
      createUserInCognito();
      setLoading(false);
      clearArtistRecord();
      clearUserRecord();
      store.dispatch(openRegistrationModal(constants.REGISTRATION_TYPE.LOGIN));
    } else if (!user.loading && user.error.includes("duplicate")) {
      toast.warn("Duplicate Data Found", { position: "top-right" });
      setLoading(false);
    }
  }, [artist, user]);

  const createUserInCognito = async () => {
    const signUpUser = await Auth.signUp({
      username: userDetails.username,
      password: userDetails.password,
      attributes: {
        phone_number: userDetails.prefix + userDetails.phone,
        email: userDetails.email,
      },
    });

    toast.success(
      `Email for verification has been sent to ${signUpUser.codeDeliveryDetails.Destination}. Please Click on Verification Link to continue.`,
      { position: "top-right" }
    );
  };

  const onFinishFailed = (errorInfo) => {};

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select className="login-right-input-prefix">
        <Option value="+91">+91</Option>
        <Option value="+1">+1</Option>
      </Select>
    </Form.Item>
  );

  return (
    <S.LoginRight>
      <h1>Register</h1>
      <Form
        name="login"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          prefix: "+91",
        }}
      >
        <Tooltip title="This will be your unique public profile link eg. www.kalacube.com/artist/username">
          <p
            style={{
              margin: 0,
              fontSize: "8px",
              padding: "0 5px",
              textAlign: "left",
            }}
          >
            This will be your unique public profile link eg.
            www.kalacube.com/artist/username
          </p>
        </Tooltip>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your UserName",
            },
            {
              validator: (_, value) =>
                value.includes(" ")
                  ? Promise.reject(
                      new Error("UserName muse not include space.")
                    )
                  : Promise.resolve(),
            },
          ]}
          normalize={(value) => value?.trim() || ""}
        >
          <Input
            className="login-right-input"
            placeholder="User Name"
            size="large"
            maxLength="30"
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
          normalize={(value) => value?.trim() || ""}
        >
          <Input
            className="login-right-input"
            placeholder="Email Id"
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
            {
              message: "Please enter correct phone number!",
              pattern: new RegExp(/^[0-9]+$/),
            },
            {
              min: 10,
              max: 10,
              message: "Please enter correct phone number!",
            },
          ]}
        >
          <Input
            className="login-right-input"
            placeholder="Phone Number"
            addonBefore={prefixSelector}
            size="large"
            minLength="10"
            maxLength="10"
          />
        </Form.Item>

        <Popover
          placement="right"
          title={title}
          content={passwordPolicyContent}
          trigger="focus"
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                validator: validateToNextPassword,
              },
            ]}
          >
            <Input.Password
              className="login-right-input"
              placeholder="Password"
              size="large"
            />
          </Form.Item>
        </Popover>

        <Form.Item
          name="remember"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Should accept Terms & Condition")
                    ),
            },
          ]}
        >
          <Checkbox>
            <Button type="link" onClick={showModal}>
              View Terms & Condition
            </Button>
          </Checkbox>
        </Form.Item>

        <Form.Item className="text-center">
          <Button
            loading={loading}
            className="login-right-button"
            style={{ borderRadius: "4px" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <div className="switch-button text-center">
        <p>
          Already on KalaCube?{" "}
          <Button
            type="link"
            size="large"
            onClick={() => store.dispatch(openRegistrationModal("login"))}
          >
            Sign in
          </Button>
        </p>
      </div>

      <PopUpTerm
        title={termsAndCondition.title}
        content={termsAndCondition.content}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </S.LoginRight>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    artist: state.artist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUserRecord: (data) => dispatch(createUser(data)),
    clearArtistRecord: () => dispatch(clearArtist()),
    clearUserRecord: () => dispatch(clearUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
