import React, { useEffect, useState } from "react";
import { Tooltip, Button, Tabs, Spin, Modal } from "antd";
import "./styles.css";
import SubmitArtCard from "../../components/SubmitArtWorkComponents/SubmitArtCard";
import { AppstoreAddOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
  fetchArtWork,
  setSelectedArtWork,
  closeSubmitArtWorkModal,
  openUploadArtModal,
} from "../../Redux";
import store from "../../Redux/store";
import { Storage } from "aws-amplify";
import SubmitArtWorkEdit from "./Edit";

const { TabPane } = Tabs;

const SubmitArtWorkView = ({
  artWorkData,
  openModal,
  submitArtWorkModal,
  closeSubmitArtWorkModal,
  setSelectedArtWork,
}) => {
  const [allImage, setAllImage] = useState([]);

  useEffect(() => {
    const promise = [];

    if (artWorkData.loading || !artWorkData.artistArtWork) return;

    artWorkData.artistArtWork.forEach((element) => {
      element.data.forEach((item) => {
        promise.push(
          Storage.list(`artist_work/${item?._id}`, { level: "protected" })
        );
      });
    });

    Promise.all(promise).then((res) => {
      const promise2 = [];
      res.forEach((item) => {
        if (item.length > 0) {
          promise2.push(Storage.get(item[0].key, { level: "protected" }));
        }
      });

      Promise.all(promise2).then((res) => {
        setAllImage(res);
      });
    });
  }, [artWorkData]);

  return (
    <Modal
      width={window.innerWidth < 768 ? "95%" : "75%"}
      title="Upload ArtWork"
      footer={null}
      visible={submitArtWorkModal}
      onCancel={() => closeSubmitArtWorkModal()}
    >
      <SubmitArtWorkEdit />
      <div className="submit-art-work">
        <Tooltip title="Upload New Art Work">
          <PlusCircleOutlined
            onClick={() => {
              setSelectedArtWork([]);
              openModal();
            }}
            className="upload-art-work-button"
          />
        </Tooltip>
        {artWorkData.loading ? (
          <div
            className="text-center"
            style={{
              height: "75vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" spinning={artWorkData.loading} />
          </div>
        ) : (
          <Tabs className="submit" defaultActiveKey="0" centered>
            {artWorkData.artistArtWork &&
              artWorkData.artistArtWork.map((item, index) => (
                <TabPane tab={item._id} key={index}>
                  <div className="art-work-list">
                    <div className="row " style={{ placeContent: "center" }}>
                      {item.data.map((artWork, index) => (
                        <SubmitArtCard
                          data={artWork}
                          imgSrc={allImage.find((item) =>
                            item.includes(artWork._id)
                          )}
                          key={index}
                        />
                      ))}
                    </div>
                  </div>
                </TabPane>
              ))}
          </Tabs>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    artWorkData: state.artWork,
    submitArtWorkModal: state.submitArtWorkModal.visible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: () => dispatch(openUploadArtModal()),
    closeSubmitArtWorkModal: () => dispatch(closeSubmitArtWorkModal()),
    setSelectedArtWork: (data) => dispatch(setSelectedArtWork(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubmitArtWorkView);
