import {
  FETCH_ARTWORK_REQUEST,
  FETCH_ARTWORK_SUCCESS,
  FETCH_ARTWORK_FAILURE,
  POST_ARTWORK_REQUEST,
  POST_ARTWORK_SUCCESS,
  POST_ARTWORK_FAILURE,
  UPDATE_ARTWORK_REQUEST,
  UPDATE_ARTWORK_SUCCESS,
  UPDATE_ARTWORK_FAILURE,
  DELETE_ARTWORK_REQUEST,
  DELETE_ARTWORK_SUCCESS,
  DELETE_ARTWORK_FAILURE,
  USERID_ARTWORK_REQUEST,
  USERID_ARTWORK_SUCCESS,
  USERID_ARTWORK_FAILURE,
  CLEAR_STORE,
  SET_SELECTED_ART_WORK,
  PUBLIC_ARTWORK_SUCCESS,
  PUBLIC_ARTWORK_REQUEST,
  FETCH_RELATED_ARTWORK_REQUEST,
  FETCH_RELATED_ARTWORK_SUCCESS,
  FETCH_RELATED_ARTWORK_FAILURE,
  PUBLIC_ARTWORK_FAILURE,
  FETCH_ALL_ARTWORK_REQUEST,
  FETCH_ALL_ARTWORK_SUCCESS,
  FETCH_ALL_ARTWORK_FAILURE,
  GET_ALL_ARTWORK_CHUNK_REQUEST,
  GET_ALL_ARTWORK_CHUNK_FAILURE,
  GET_ALL_ARTWORK_CHUNK_SUCCESS,
  GET_ALL_ARTWORK_CHUNK_RESET,
  GET_RECENT_ARTWORK_REQUEST,
  GET_RECENT_ARTWORK_SUCCESS,
  GET_RECENT_ARTWORK_FAILURE,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
} from "./type";

const initialState = {
  loading: false,
  selectedArtWork: [],
  artistArtWork: [],
  error: "",
  relatedArtWork: {
    loading: false,
    data: null,
  },
  relatedArtWorkData: {
    loading: false,
    data: null,
  },
  allArtWork: {
    loading: false,
    data: null,
  },
  allArtWorkChunk: {
    loading: false,
    data: [],
  },
  recentArtWork: {
    loading: false,
    data: [],
  },
  artWorkTags: {
    loading: false,
    data: [],
  },
  createTagLoading: false,
  deleteTagLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTWORK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ARTWORK_SUCCESS:
      return {
        ...state,
        loading: false,
        artistArtWork: action.payload,
        error: "",
      };
    case FETCH_ARTWORK_FAILURE:
      return {
        loading: false,
        selectedArtWork: [],
        error: action.payload,
      };
    case USERID_ARTWORK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USERID_ARTWORK_SUCCESS:
      return {
        loading: false,
        selectedArtWork: action.payload,
        error: "",
      };
    case USERID_ARTWORK_FAILURE:
      return {
        loading: false,
        artist: [],
        error: action.payload,
      };
    case POST_ARTWORK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_ARTWORK_SUCCESS:
      return {
        loading: false,
        selectedArtWork: action.payload,
        error: "",
        success: action.success,
      };
    case POST_ARTWORK_FAILURE:
      return {
        loading: false,
        selectedArtWork: [],
        error: action.payload,
      };
    case DELETE_ARTWORK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ARTWORK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.success,
      };
    case DELETE_ARTWORK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_ARTWORK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ARTWORK_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedArtWork: action.payload,
        error: "",
        success: action.success,
      };
    case UPDATE_ARTWORK_FAILURE:
      return {
        ...state,
        loading: false,
        selectedArtWork: [],
        error: action.payload,
      };
    case CLEAR_STORE:
      return {
        ...initialState,
      };
    case SET_SELECTED_ART_WORK:
      return {
        ...state,
        selectedArtWork: action.payload,
      };
    case PUBLIC_ARTWORK_REQUEST:
      return {
        ...state,
        loading: true,
        publicArtWork: undefined,
      };
    case PUBLIC_ARTWORK_SUCCESS:
      return {
        ...state,
        loading: false,
        publicArtWork: action.payload,
        error: "",
        success: action.success,
      };
    case PUBLIC_ARTWORK_FAILURE:
      return {
        ...state,
        loading: false,
        publicArtWork: undefined,
        error: "",
        success: action.success,
      };
    case FETCH_RELATED_ARTWORK_REQUEST:
      return {
        ...state,
        relatedArtWork: {
          loading: true,
          data: null,
        },
      };
    case FETCH_RELATED_ARTWORK_SUCCESS:
      return {
        ...state,
        relatedArtWork: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_RELATED_ARTWORK_FAILURE:
      return {
        ...state,
        relatedArtWork: {
          loading: false,
          data: null,
        },
      };
    case FETCH_ALL_ARTWORK_REQUEST:
      return {
        ...state,
        allArtWork: {
          loading: true,
          data: null,
        },
      };
    case FETCH_ALL_ARTWORK_SUCCESS:
      return {
        ...state,
        allArtWork: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_ALL_ARTWORK_FAILURE:
      return {
        ...state,
        allArtWork: {
          loading: false,
          data: null,
        },
      };
    case GET_ALL_ARTWORK_CHUNK_REQUEST:
      return {
        ...state,
        allArtWorkChunk: {
          data: action.payload.append ? state.allArtWorkChunk.data : [],
          loading: true,
          timestamp: action.payload.timestamp,
        },
      };
    case GET_ALL_ARTWORK_CHUNK_SUCCESS:
      if (state.allArtWorkChunk.timestamp > action.payload.timestamp) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        allArtWorkChunk: {
          loading: false,
          data: action.payload.append
            ? [...state.allArtWorkChunk.data, ...action.payload.data]
            : action.payload.data,
          timestamp: action.payload.timestamp,
        },
      };
    case GET_ALL_ARTWORK_CHUNK_FAILURE:
      return {
        ...state,
        allArtWorkChunk: {
          ...state.allArtWorkChunk,
          loading: false,
        },
      };
    case GET_RECENT_ARTWORK_REQUEST:
      return {
        ...state,
        recentArtWork: {
          loading: true,
          data: [],
        },
      };
    case GET_RECENT_ARTWORK_FAILURE:
      return {
        ...state,
        recentArtWork: {
          loading: false,
          data: [],
        },
      };
    case GET_RECENT_ARTWORK_SUCCESS:
      return {
        ...state,
        recentArtWork: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_TAGS_REQUEST:
      return {
        ...state,
        artWorkTags: {
          loading: true,
          data: [],
        },
      };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        artWorkTags: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_TAGS_FAILURE:
      return {
        ...state,
        artWorkTags: {
          loading: false,
          data: [],
        },
      };
    case CREATE_TAG_REQUEST:
      return {
        ...state,
        createTagLoading: true,
      };
    case CREATE_TAG_SUCCESS:
    case CREATE_TAG_FAILURE:
      return {
        ...state,
        createTagLoading: false,
      };
    case DELETE_TAG_REQUEST:
      return {
        ...state,
        deleteTagLoading: true,
      };
    case DELETE_TAG_SUCCESS:
    case DELETE_TAG_FAILURE:
      return {
        ...state,
        deleteTagLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
