import { OPEN_ARTWORK_MODAL, CLOSE_ARTWORK_MODAL } from "./type";

export const openArtWorkModal = (data, action, isAdmin) => ({
  type: OPEN_ARTWORK_MODAL,
  payload: {
    data,
    action,
    isAdmin,
  },
});

export const closeArtWorkModal = () => ({
  type: CLOSE_ARTWORK_MODAL,
});
