import { API } from "aws-amplify";
import axios from "axios";
import {
    FETCH_POPULARARTIST_REQUEST,
    FETCH_POPULARARTIST_SUCCESS,
    FETCH_POPULARARTIST_FAILURE,
    
} from './type';

const apiName = 'backend-api';
const path = '/api/artists/getPopularArtists';
const myInit = { // OPTIONAL
    headers: {}, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    // queryStringParameters: {  // OPTIONAL
    //   name: 'param',
    // },
};


export const fetchPopularArtist =() => {
    return(dispatch) =>{
        dispatch(fetchPopularArtistRequest())
        API.get(apiName, path, myInit)
            .then(response => {

                  // response.data is the users
        const popularArtist = response.data
        dispatch(fetchPopularArtistSuccess(popularArtist))
        
      })
      .catch(error => {
        // error.message is the error message
        if (error.message) {
          dispatch(fetchPopularArtistFailure(error.message))
        } else {
          dispatch(fetchPopularArtistFailure(error.response.data.message))
        }
      })
    }
}


export const fetchPopularArtistRequest = () => {
    return {
        type: FETCH_POPULARARTIST_REQUEST
    }
}

export const fetchPopularArtistSuccess = popularArtist => {
    return {
        type: FETCH_POPULARARTIST_SUCCESS,
        payload: popularArtist,
        success: "succesful"
    }
}

export const fetchPopularArtistFailure = error => {
    return {
        type: FETCH_POPULARARTIST_FAILURE,
        payload: error
    }
}
