import {
  FETCH_ARTIST_REQUEST,
  FETCH_ARTIST_SUCCESS,
  FETCH_ARTIST_FAILURE,
  UPDATE_ARTIST_REQUEST,
  UPDATE_ARTIST_SUCCESS,
  UPDATE_ARTIST_FAILURE,
  DELETE_ARTIST_REQUEST,
  DELETE_ARTIST_SUCCESS,
  DELETE_ARTIST_FAILURE,
  POST_ARTIST_REQUEST,
  POST_ARTIST_SUCCESS,
  POST_ARTIST_FAILURE,
  USERNAME_ARTIST_REQUEST,
  USERNAME_ARTIST_SUCCESS,
  USERNAME_ARTIST_FAILURE,
  // FETCH_ALL_ARTIST_REQUEST,
  // FETCH_ALL_ARTIST_SUCCESS,
  // FETCH_ALL_ARTIST_FAILURE,
  CLEAR_STORE,
} from "./type";

const initialState = {
  loading: false,
  artist: undefined,
  error: "",
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ARTIST_SUCCESS:
      return {
        loading: false,
        artist: action.payload,
        error: "",
      };
    case FETCH_ARTIST_FAILURE:
      return {
        loading: false,
        artist: undefined,
        error: action.payload,
      };
    case USERNAME_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USERNAME_ARTIST_SUCCESS:
      return {
        loading: false,
        artist: action.payload,
        error: "",
      };
    case USERNAME_ARTIST_FAILURE:
      return {
        loading: false,
        artist: undefined,
        error: action.payload,
      };
    case POST_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_ARTIST_SUCCESS:
      return {
        loading: false,
        artist: action.payload,
        error: "",
        success: action.success,
      };
    case POST_ARTIST_FAILURE:
      return {
        loading: false,
        artist: undefined,
        error: action.payload,
      };
    case DELETE_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ARTIST_SUCCESS:
      return {
        loading: false,
        artist: action.payload,
        error: "",
        success: action.success,
      };
    case DELETE_ARTIST_FAILURE:
      return {
        loading: false,
        artist: undefined,
        error: action.payload,
      };
    case UPDATE_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ARTIST_SUCCESS:
      return {
        loading: false,
        artist: action.payload,
        error: "",
        success: action.success,
      };
    case UPDATE_ARTIST_FAILURE:
      return {
        loading: false,
        artist: undefined,
        error: action.payload,
      };
    case CLEAR_STORE:
      //
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
