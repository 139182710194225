import { OPEN_ARTWORK_MODAL, CLOSE_ARTWORK_MODAL } from "./type";

const initialValues = {
  visible: false,
  data: null,
  action: "",
};

const artWorkModalReducer = (state = initialValues, action) => {
  switch (action.type) {
    case OPEN_ARTWORK_MODAL:
      return {
        visible: true,
        data: action.payload.data,
        action: action.payload.action,
        isAdmin: action.payload.isAdmin,
      };
    case CLOSE_ARTWORK_MODAL:
      return {
        visible: false,
        data: null,
        action: "",
      };
    default:
      return {
        ...state,
      };
  }
};

export default artWorkModalReducer;
