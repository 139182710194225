import {
  GET_USER_ROLE_FAILURE,
  GET_USER_ROLE_REQUEST,
  GET_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILURE,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCCESS,
} from "./type";

const initialState = {
  userRole: {
    loading: false,
    data: null,
  },
  updateUserRole: false,
};

export default function userRoleReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_ROLE_REQUEST:
      return {
        ...state,
        userRole: {
          loading: true,
          data: null,
        },
      };
    case GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        userRole: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_USER_ROLE_FAILURE:
      return {
        ...state,
        userRole: {
          loading: false,
          data: null,
        },
      };
    case UPDATE_USER_ROLE_REQUEST:
      return {
        ...state,
        updateUserRole: true,
      };
    case UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        updateUserRole: false,
      };
    case UPDATE_USER_ROLE_FAILURE:
      return {
        ...state,
        updateUserRole: false,
      };
    default:
      return {
        ...state,
      };
  }
}
