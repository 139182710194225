import { Modal } from "antd";
import React from "react";

const PromotionModal = ({ promotionModal, setPromotionModal }) => {
  return (
    <Modal
      title={"Announcement"}
      width="450px"
      footer={null}
      visible={promotionModal.visible}
      onCancel={() => setPromotionModal({ visible: false })}
      bodyStyle={{
        padding: 0,
      }}
    >
      <img
        src="/img/promotion.jpeg"
        alt="promotion"
        style={{
          width: "100%",
          objectFit: "contain",
        }}
      />
    </Modal>
  );
};

export default PromotionModal;
