import {
  INCREASE_STEP,
  DECREASE_STEP,
  OPEN_UPLOAD_ART_MODAL,
  CLOSE_UPLOAD_ART_MODAL,
} from "./type";

const initialState = {
  step: 0,
  visible: false,
};

const uploadArtReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREASE_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case DECREASE_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    case OPEN_UPLOAD_ART_MODAL:
      return {
        ...state,
        step: 0,
        visible: true,
      };
    case CLOSE_UPLOAD_ART_MODAL:
      return {
        ...state,
        step: 0,
        visible: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default uploadArtReducer;
