import { toast } from "react-toastify";
import axios from "axios";
import { API } from "aws-amplify";
import {
  GET_USER_ROLE_FAILURE,
  GET_USER_ROLE_REQUEST,
  GET_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILURE,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCCESS,
} from "./type";

const apiName = "backend-api";
const path = "/api/users/roles";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const getUserRole = (userId) => (dispatch) => {
  dispatch({
    type: GET_USER_ROLE_REQUEST,
  });
  API.get(apiName, `${path}/${userId}`)
    .then((res) => {
      console.log({ res });
      dispatch({
        type: GET_USER_ROLE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      toast.error(error.response?.data?.message || error.message || error);
      dispatch({
        type: GET_USER_ROLE_FAILURE,
      });
    });
};

export const updateUserRole = (userId, data) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_ROLE_REQUEST,
  });
  API.put(apiName, `${path}/${userId}`, {
    ...myInit,
    body: data,
  })
    .then((res) => {
      dispatch({
        type: UPDATE_USER_ROLE_SUCCESS,
      });
      dispatch(getUserRole(userId));
      toast.success("Curator role updated");
    })
    .catch((error) => {
      toast.error(error.response?.data?.message || error.message || error);
      dispatch({
        type: UPDATE_USER_ROLE_FAILURE,
      });
    });
};
