export const UNLINK_INSTAGRAM_REQUEST = "UNLINK_INSTAGRAM_REQUEST";
export const UNLINK_INSTAGRAM_SUCCESS = "UNLINK_INSTAGRAM_SUCCESS";
export const UNLINK_INSTAGRAM_FAILURE = "UNLINK_INSTAGRAM_FAILURE";

export const LINK_INSTAGRAM_REQUEST = "LINK_INSTAGRAM_REQUEST";
export const LINK_INSTAGRAM_SUCCESS = "LINK_INSTAGRAM_SUCCESS";
export const LINK_INSTAGRAM_FAILURE = "LINK_INSTAGRAM_FAILURE";

export const FETCH_INSTAGRAM_POSTS_REQUEST = "FETCH_INSTAGARM_POSTS_REQUEST";
export const FETCH_INSTAGRAM_POSTS_SUCCESS = "FETCH_INSTAGARM_POSTS_SUCCESS";
export const FETCH_INSTAGRAM_POSTS_FAILURE = "FETCH_INSTAGARM_POSTS_FAILURE";
