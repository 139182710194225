import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, TreeSelect } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { updateArtWork } from "../../../../../Redux/ArtWork/action";
import * as S from "../../../../ProfileComponents/ProfileEdit/ArtistStyles/styles";

const { TreeNode } = TreeSelect;

const ArtistStyles = ({
  updateArtWorkData,
  artistData,
  setSelectedArtStyle,
  selectedArtStyle,
}) => {
  const [artistStyles, setArtistStyles] = useState([]);
  const [servicesColor] = useState(["first", "second", "third"]);

  useEffect(() => {
    setArtistStyles(artistData.artist.artStyleId);
  }, [artistData]);

  return (
    <Card className="w-100" title="Select Art Style">
      <S.ArtStylesDiv>
        {artistStyles.map((speciality, index) => (
          <div
            className={`service-box ${servicesColor[index]} ${
              speciality.id === selectedArtStyle ? "selected" : ""
            }`}
            onClick={() => setSelectedArtStyle(speciality.id)}
          >
            <p className="category">{speciality.category}</p>
            <p className="speciality">{speciality.specialist}</p>
          </div>
        ))}
      </S.ArtStylesDiv>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    artWork: state.artWork,
    artistData: state.artist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateArtWorkData: (id, data) => dispatch(updateArtWork(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtistStyles);
