import { API, Storage } from "aws-amplify";
import { toast } from "react-toastify";
import {
  CREATE_ALT_SPACE_FAILURE,
  CREATE_ALT_SPACE_REQUEST,
  CREATE_ALT_SPACE_SUCCESS,
  DELETE_ALT_SPACE_FAILURE,
  DELETE_ALT_SPACE_REQUEST,
  DELETE_ALT_SPACE_SUCCESS,
  GET_ALL_ALT_SPACES_FAILURE,
  GET_ALL_ALT_SPACES_REQUEST,
  GET_ALL_ALT_SPACES_SUCCESS,
  UPDATE_ALT_SPACE_FAILURE,
  UPDATE_ALT_SPACE_REQUEST,
  UPDATE_ALT_SPACE_SUCCESS,
  GET_ALT_SPACE_FAILURE,
  GET_ALT_SPACE_SUCCESS,
  GET_ALT_SPACE_REQUEST,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  GET_ALT_SPACE_DROPDOWN_REQUEST,
  GET_ALT_SPACE_DROPDOWN_SUCCESS,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
} from "./type";
import moment from "moment";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

const apiName = "backend-api";
const path = "/api/alt-spaces";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const getAllAltSpacesRequest = {
  type: GET_ALL_ALT_SPACES_REQUEST,
};

export const getAllAltSpacesSuccess = (data) => ({
  type: GET_ALL_ALT_SPACES_SUCCESS,
  payload: data,
});

export const getAllAltSpacesFailure = {
  type: GET_ALL_ALT_SPACES_FAILURE,
};

export const getAllAltSpaces = (filter) => (dispatch) => {
  dispatch(getAllAltSpacesRequest);
  const body = { ...myInit };
  body.queryStringParameters = filter;
  API.get(apiName, path, body)
    .then((res) => {
      dispatch(getAllAltSpacesSuccess(res.data));
    })
    .catch((error) => {
      toast.error(error.response?.data?.message || error.message || error);
      dispatch(getAllAltSpacesFailure);
    });
};

export const getAllAltSpacesWithImages = (filter) => (dispatch) => {
  dispatch(getAllAltSpacesRequest);
  API.get(apiName, path, {
    ...myInit,
    queryStringParameters: filter || {},
  })
    .then(async (res) => {
      const data = res.data;
      for (let altSpace of data) {
        altSpace.profilePic = await Storage.get(
          `alt_space/${altSpace.username}/profile_pic.png`
        );
        const mapsData = await geocodeByPlaceId(altSpace.location);
        altSpace.location = mapsData[0];
      }
      dispatch(getAllAltSpacesSuccess(res.data));
    })
    .catch((error) => {
      toast.error(error.response?.data?.message || error.message || error);
      dispatch(getAllAltSpacesFailure);
    });
};

export const createAltSpace = (data, onSuccess, onFailure) => (dispatch) => {
  dispatch({ type: CREATE_ALT_SPACE_REQUEST });
  API.post(apiName, path, { ...myInit, body: data })
    .then((res) => {
      dispatch({
        type: CREATE_ALT_SPACE_SUCCESS,
      });
      onSuccess && onSuccess(res.data);
      dispatch(getAllAltSpaces());
      toast.success("Art Space Created");
    })
    .catch((error) => {
      dispatch({
        type: CREATE_ALT_SPACE_FAILURE,
      });
      onFailure && onFailure();
      toast.error("Art Space not created. Please try again");
    });
};

export const updateAltSpace =
  (id, data, onSuccess, onFailure) => (dispatch) => {
    dispatch({ type: UPDATE_ALT_SPACE_REQUEST });
    myInit.body = data;
    API.put(apiName, `${path}/${id}`, myInit)
      .then((res) => {
        dispatch({
          type: UPDATE_ALT_SPACE_SUCCESS,
        });
        onSuccess && onSuccess(res.data);
        dispatch(getAllAltSpaces());
        toast.success("Art Space updated");
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_ALT_SPACE_FAILURE,
        });
        onFailure && onFailure();
        toast.error("Art Space not created. Please try again");
      });
  };

export const deleteAltSpace =
  (altSpace, onSuccess, onFailure) => async (dispatch) => {
    try {
      Storage.remove(`alt_space/${altSpace.username}/profile_pic.png`);
      const galleryList = await Storage.list(
        `alt_space/${altSpace.username}/gallery`
      );
      for (let galleryImage of galleryList) {
        await Storage.remove(galleryImage.key);
      }
      const eventList = await Storage.list(
        `alt_space/${altSpace.username}/events`
      );
      for (let eventImage of eventList) {
        await Storage.remove(eventImage.key);
      }
      dispatch({ type: DELETE_ALT_SPACE_REQUEST });
      const res = await API.del(apiName, `${path}/${altSpace.id}`, myInit);
      dispatch({
        type: DELETE_ALT_SPACE_SUCCESS,
      });
      onSuccess && onSuccess(res.data);
      dispatch(getAllAltSpaces());
      toast.success("Art Space deleted");
    } catch (error) {
      dispatch({
        type: DELETE_ALT_SPACE_FAILURE,
      });
      onFailure && onFailure();
      toast.error("Art Space not deleted. Please try again");
    }
  };

export const getAltSpaceRequest = {
  type: GET_ALT_SPACE_REQUEST,
};

export const getAltSpaceSuccess = (data) => ({
  type: GET_ALT_SPACE_SUCCESS,
  payload: data,
});

export const getAltSpaceFailure = {
  type: GET_ALT_SPACE_FAILURE,
};

export const getAltSpace = (username) => (dispatch) => {
  dispatch(getAltSpaceRequest);
  API.get(apiName, `${path}/username/${username}`, myInit)
    .then((res) => {
      const data = res.data;
      data.events.forEach((event) => {
        event.start = moment(event.start).local().toDate();
        event.end = moment(event.end).local().toDate();
      });
      dispatch(getAltSpaceSuccess(data));
    })
    .catch((error) => {
      dispatch(getAltSpaceFailure);
      toast.error("Unable to fetch Art Space");
    });
};

export const createEventRequest = {
  type: CREATE_EVENT_REQUEST,
};
export const createEventSuccess = (data) => ({
  type: CREATE_EVENT_SUCCESS,
  payload: data,
});
export const createEventFailure = {
  type: CREATE_EVENT_FAILURE,
};

export const createEvent = (data, onSuccess, onError) => (dispatch) => {
  dispatch(createEventRequest);
  API.post(apiName, "/api/events", {
    ...myInit,
    body: data,
  })
    .then((res) => {
      toast.success("Event added");
      dispatch(createEventSuccess(res.data));
      onSuccess && onSuccess();
    })
    .catch((error) => {
      toast.error("Failed, Please try again");
      dispatch(createEventFailure);
      onError && onError();
    });
};

export const fetchAlternateSpaceDropdown =
  (alternateSpaceList) => (dispatch) => {
    dispatch({ type: GET_ALT_SPACE_DROPDOWN_REQUEST });
    API.get(apiName, `${path}/dropdown`)
      .then((res) => {
        dispatch({
          type: GET_ALT_SPACE_DROPDOWN_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_ALT_SPACES_FAILURE,
        });
      });
  };

export const deleteEvent = (id, onSuccess, onError) => (dispatch) => {
  dispatch({ type: DELETE_EVENT_REQUEST });
  API.del(apiName, `/api/events/${id}`, myInit)
    .then((res) => {
      dispatch({ type: DELETE_EVENT_SUCCESS });
      toast.success("Event deleted");
      onSuccess && onSuccess();
    })
    .catch((error) => {
      dispatch({ type: DELETE_EVENT_FAILURE });
      toast.error("Failed to delete Event, Please try again");
      onError && onError();
    });
};

export const updateEventRequest = {
  type: UPDATE_EVENT_REQUEST,
};
export const updateEventSuccess = (data) => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: data,
});
export const updateEventFailure = {
  type: UPDATE_EVENT_FAILURE,
};

export const updateEvent = (id, data, onSuccess, onError) => (dispatch) => {
  dispatch(updateEventRequest);
  API.put(apiName, `/api/events/${id}`, {
    ...myInit,
    body: data,
  })
    .then((res) => {
      toast.success("Event updated");
      dispatch(updateEventSuccess(res.data));
      onSuccess && onSuccess();
    })
    .catch((error) => {
      toast.error("Failed, Please try again");
      dispatch(updateEventFailure);
      onError && onError();
    });
};
