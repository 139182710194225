import React, { useEffect } from "react";
import { Row, Col, Card } from "antd";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";

const Thankyou = ({ userData }) => {
  useEffect(() => {}, [userData]);

  return (
    <Row className="pl-2 pr-2 welcome-screen-container">
      <Col span={24} className="mb-20">
        <h1>Thankyou</h1>
      </Col>
      <Col span={24} className="content-sec">
        <Row>
          <Col span={24}>
            <Card className="w-100">
              {/* <p className="desc">
                Your initial profile is complete and is ready for Art Lover's to find you.<br></br>
              You can go through the Profile Settings and make any updates that you have just filled out, at any time.<br></br><br></br>
                <i>Click On Finish To View Your Profile Page.</i>
              </p> */}
              <p className="desc">
                Click on finish to create your public portfolio i.e.{" "}
                <a
                  target="_blank"
                  href={`www.kalacube/artist/${userData.user.userName}`}
                >
                  www.kalacube/artist/{userData.user.userName}
                </a>
                .<br></br>
                <br></br>
                <i>
                  You can now share your curated portfolio with the world and
                  showcase your work.
                </i>
              </p>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user,
  };
};

export default connect(mapStateToProps)(Thankyou);
