import React, { useEffect, useState } from "react";
import { Upload, Col, Modal, Tooltip, Card } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CropModal from "./CropModal";
import PreviewModal from "./PreviewModal";

const { Meta } = Card;

const { Dragger } = Upload;

const ArtUpload = ({
  setShowArtWorkInformation,
  setFetchingArtistWork,
  artWorkData,
  isAdmin,
}) => {
  const { artist } = useSelector((state) => ({
    artist: isAdmin ? state.publicArtist.publicArtist : state.artist.artist,
  }));

  const [fileList, setFileList] = useState([]);
  const [previewModal, setPreviewModal] = useState({
    visible: false,
    data: null,
  });

  const fetchArtistWork = () => {
    setFetchingArtistWork(true);
    Storage.list(
      `artist_work/${artWorkData.id ? artWorkData.id : artWorkData._id}`,
      { level: "protected", identityId: artist.userId.identityId }
    )
      .then((result) => {
        const promise = [];
        const data = [];
        result.forEach((item) =>
          promise.push(
            Storage.get(item.key, {
              level: "protected",
              identityId: artist.userId.identityId,
            })
          )
        );

        Promise.all(promise).then((res) => {
          res.forEach((element, index) => {
            data.push({ uid: index, url: element });
          });
          setFetchingArtistWork(false);
          setFileList(data);
        });
      })
      .catch((err) => {
        setFetchingArtistWork(false);
      });
  };

  useEffect(() => {
    setShowArtWorkInformation(fileList && fileList.length > 0);
  }, [fileList]);

  useEffect(() => {
    fetchArtistWork();
  }, [artWorkData]);

  const discardImage = () => {
    setFileList((oldValue) => [...oldValue.slice(0, oldValue.length - 1)]);
  };

  const uploadImage = async (
    options,
    image,
    aspectRatio,
    onUploadSuccess,
    onUploadError
  ) => {
    const { onSuccess, onError, onProgress } = options;
    Storage.put(
      `artist_work/${
        artWorkData.id ? artWorkData.id : artWorkData._id
      }/${Date.now()}/${image.name}`,
      image,
      {
        level: "protected",
        identityId: artist.userId.identityId,
        contentType: "image",
        progressCallback(progress) {
          onProgress(
            {
              percent: Math.round((progress.loaded / progress.total) * 100),
            },
            image
          );
        },
      }
    )
      .then((result) => {
        onSuccess("Ok");
        fetchArtistWork();
        toast.success("Art Work Uploaded");
        onUploadSuccess && onUploadSuccess();
      })
      .catch((err) => {
        onError({ err });
        onUploadError && onUploadError();
        toast.error("Unsuccessful");
      });
  };

  const handlePreview = async (file) => {
    if (file.url) {
      setPreviewModal({
        visible: true,
        data: file.url,
      });
    } else {
      setPreviewModal({
        visible: true,
        data: file.thumbUrl,
      });
    }
  };

  const handleOnChange = ({ file, fileList, event }) => {
    setFileList(fileList);
  };

  const [cropModal, setCropModal] = useState({
    visible: false,
    data: null,
  });

  const onRemove = async (file) => {
    const url = decodeURIComponent(file.url);
    const key = url.substring(url.indexOf("artist_work"), url.indexOf("?"));
    await Storage.remove(key, {
      level: "protected",
      identityId: artist.userId.identityId,
    });
    setFileList((oldValue) => [
      ...oldValue.filter((item) => item.url !== file.url),
    ]);
    toast.success("Art Work Deleted");
  };

  return (
    <div className="pl-2 pr-2 welcome-screen-container">
      <div className="mt-2">
        <Tooltip title="Upload Art">
          <Dragger
            multiple={false}
            accept="image/*"
            customRequest={(options) => {
              setCropModal({
                visible: true,
                data: options,
              });
            }}
            onPreview={handlePreview}
            listType="picture-card"
            fileList={fileList}
            onRemove={onRemove}
          >
            <div
              style={{
                // minHeight: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </div>
          </Dragger>
        </Tooltip>
      </div>
      {cropModal.visible && (
        <CropModal
          cropModal={cropModal}
          setCropModal={setCropModal}
          onCompleteCrop={uploadImage}
          discardImage={discardImage}
          setFileList={setFileList}
        />
      )}
      {previewModal.visible && (
        <PreviewModal
          previewModal={previewModal}
          setPreviewModal={setPreviewModal}
        />
      )}
    </div>
  );
};

export default ArtUpload;
