// Fetch
export const FETCH_ARTSTYLE_REQUEST = 'FETCH_ARTSTYLE_REQUEST'
export const FETCH_ARTSTYLE_SUCCESS = 'FETCH_ARTSTYLE_SUCCESS'
export const FETCH_ARTSTYLE_FAILURE = 'FETCH_ARTSTYLE_FAILURE'

// Create
export const POST_ARTSTYLE_REQUEST = 'POST_ARTSTYLE_REQUEST'
export const POST_ARTSTYLE_SUCCESS = 'POST_ARTSTYLE_SUCCESS'
export const POST_ARTSTYLE_FAILURE = 'POST_ARTSTYLE_FAILURE'

//delete
export const DELETE_ARTSTYLE_REQUEST = 'DELETE_ARTSTYLE_REQUEST'
export const DELETE_ARTSTYLE_SUCCESS = 'DELETE_ARTSTYLE_SUCCESS'
export const DELETE_ARTSTYLE_FAILURE = 'DELETE_ARTSTYLE_FAILURE'

//update

export const UPDATE_ARTSTYLE_REQUEST = 'UPDATE_ARTSTYLE_REQUEST'
export const UPDATE_ARTSTYLE_SUCCESS = 'UPDATE_ARTSTYLE_SUCCESS'
export const UPDATE_ARTSTYLE_FAILURE = 'UPDATE_ARTSTYLE_FAILURE'

//store clear 

export const CLEAR_STORE = 'CLEAR_STORE'


