import { lazy, Suspense, useEffect, useState } from "react";
import { Switch, useLocation } from "react-router-dom";

import routes from "./config";
import GlobalStyles from "../globalStyles";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import ReactGA from "react-ga4";

const StickyButtons = lazy(() => import("../components/StickyButtons"));

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("G-F23393ZQJH");
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [initialized, location]);
};

const Router = () => {
  usePageTracking();
  return (
    <Suspense fallback={null}>
      <GlobalStyles />
      <StickyButtons />
      <Switch>
        {routes.map((routeItem) => {
          return routeItem.public ? (
            <PublicRoute
              key={routeItem.component}
              restricted={routeItem.restricted}
              path={routeItem.path}
              exact={routeItem.exact}
              role={routeItem.role}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          ) : (
            <PrivateRoute
              key={routeItem.component}
              restricted={routeItem.restricted}
              path={routeItem.path}
              exact={routeItem.exact}
              role={routeItem.role}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Router;
