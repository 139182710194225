import React from 'react';
import { Row, Col, Card } from 'antd';

const Welcome = () => {
  return (
    <Row className="pl-2 pr-2 welcome-screen-container">
      <Col span={24} className="mb-20">
        <h1>Upload</h1>
      </Col>
      <Col span={24} className="content-sec">
        <Row>
          <Col span={24} >
            <Card title="Information"
              style={{ width: "100%" }}>
              <p className="desc">
              Let's start with uploading your artwork for the selected art style.
                      </p>
              <p className="desc">
              Don’t worry, nothing here is set in stone. You can always come back to upload fresh artwork / delete existing artwork any time.
                      </p>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Welcome;