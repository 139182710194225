import React, { useEffect, useState } from "react";
// import 'antd/dist/antd.css';
import { Row, Col, Button, Tooltip, Form, Skeleton, Modal, Spin } from "antd";
import { connect } from "react-redux";
// import { fetchArtist} from './../../Redux/Artist/action';
import {
  createArtWork,
  fetchArtWork,
  setSelectedArtWork,
  updateArtWork,
} from "../../../Redux/ArtWork/action";

import { useHistory } from "react-router-dom";
// import './styles.css';
import Navigation from "./Navigation";
import Welcome from "./Welcome";
import "./styles.css";
import BasicInformation from "./ArtInfo";

import { CloseCircleOutlined } from "@ant-design/icons";
import Thankyou from "./Thankyou";
import ArtUpload from "./ArtUpload";
import store from "../../../Redux/store";
import { ToastContainer, toast } from "react-toastify";
import {
  closeUploadArtModal,
  decreaseStep,
  increaseStep,
  openUploadArtModal,
} from "../../../Redux/UploadArtModal/action";
import Fade from "react-reveal/Fade";

const ProfileEditComponent = ({
  artistData,
  createArtWorkData,
  artWorkData,
  updateArtWorkData,
  step,
  increaseCounter,
  decreaseCounter,
  isUploadModalVisible,
  openModal,
  closeModal,
}) => {
  const [basicForm, artistStylesForm] = Form.useForm();
  const [width, setWidth] = useState(window.innerWidth);
  // const [fileList]=

  const [selectedArtStyle, setSelectedArtStyle] = React.useState();
  const [showArtWorkInformation, setShowArtWorkInformation] =
    React.useState(false);
  const [fetchingArtistWork, setFetchingArtistWork] = React.useState(false);

  const history = useHistory();

  const increaseCounterCustom = () => {
    // history check
    if (step === 1) {
      if (selectedArtStyle) {
        const data = {
          ...basicForm.getFieldsValue(),
          artStyleId: selectedArtStyle,
          artistId: artistData.artist.id,
        };

        basicForm
          .validateFields()
          .then((res) => {
            updateArtWorkData(
              artWorkData.selectedArtWork.id
                ? artWorkData.selectedArtWork.id
                : artWorkData.selectedArtWork._id,
              data
            );
          })
          .catch((err) => {
            console.error(err);
          });
        increaseCounter();
      } else {
        toast.warn("Select Art Style");
      }
    } else if (step === 0) {
      if (artWorkData.selectedArtWork && artWorkData.selectedArtWork._id) {
        increaseCounter();
      } else {
        createArtWorkData({});
        increaseCounter();
      }
    } else {
      increaseCounter();
    }
  };

  useEffect(() => {
    // if (!artWorkData.loading && step === 1) {
    //   increaseCounter();
    // }

    if (artWorkData.selectedArtWork) {
      basicForm.setFieldsValue(artWorkData.selectedArtWork);

      if (artWorkData.selectedArtWork.artStyleId) {
        setSelectedArtStyle(artWorkData.selectedArtWork.artStyleId._id);
      }
    }
  }, [artWorkData]);

  useEffect(() => {
    window.onresize = () => setWidth(window.innerWidth);
    return () => {
      window.onresize = null;
    };
  }, []);

  return (
    <Modal
      className="upload-art"
      title="Upload ArtWork"
      width={Math.min(Number(width) - 30, 1000)}
      footer={null}
      visible={isUploadModalVisible}
      onCancel={() => closeModal()}
    >
      {artWorkData.loading ? (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Row gutter={16}>
              <Col xs={24} sm={6} md={6} lg={5} xl={5}>
                <Navigation step={step} />
              </Col>
              <Col xs={24} sm={18} md={18} lg={19} xl={19}>
                {step === 0 && <Welcome />}
                {step === 1 && (
                  <>
                    <ArtUpload
                      setShowArtWorkInformation={setShowArtWorkInformation}
                      setFetchingArtistWork={setFetchingArtistWork}
                    />
                    <Fade collapse when={fetchingArtistWork}>
                      <div className="loading">
                        <Spin />
                      </div>
                    </Fade>
                    <Fade collapse when={showArtWorkInformation}>
                      <BasicInformation
                        basicForm={basicForm}
                        selectedArtStyle={selectedArtStyle}
                        setSelectedArtStyle={setSelectedArtStyle}
                      />
                    </Fade>
                  </>
                )}
                {/* {step === 2 && (
                  <BasicInformation
                    basicForm={basicForm}
                    selectedArtStyle={selectedArtStyle}
                    setSelectedArtStyle={setSelectedArtStyle}
                  />
                )} */}
                {step === 2 && <Thankyou />}
                <div
                  className="pt-4"
                  style={{ float: "right", paddingRight: "10px" }}
                >
                  {step > 0 && step < 2 && (
                    <Button
                      style={{
                        background: "rgba(50,53,136,255)",
                        color: "white",
                        borderRadius: "6%",
                      }}
                      onClick={decreaseCounter}
                    >
                      Back
                    </Button>
                  )}

                  {step < 2 ? (
                    <Button
                      style={{
                        marginLeft: "10px",
                        background: "rgba(50,53,136,255)",
                        color: "white",
                        borderRadius: "6%",
                      }}
                      onClick={increaseCounterCustom}
                      loading={artWorkData.loading}
                      disabled={step === 1 && fetchingArtistWork}
                    >
                      {step === 0 ? "Let's Get Started" : "Save & Next"}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        background: "rgba(50,53,136,255)",
                        color: "white",
                        borderRadius: "6%",
                      }}
                      onClick={() => closeModal()}
                    >
                      Finish
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    artistData: state.artist,
    artWorkData: state.artWork,
    step: state.uploadArtModal.step,
    isUploadModalVisible: state.uploadArtModal.visible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateArtWorkData: (id, data) => dispatch(updateArtWork(id, data)),
    createArtWorkData: (data) => dispatch(createArtWork(data)),
    increaseCounter: () => dispatch(increaseStep()),
    decreaseCounter: () => dispatch(decreaseStep()),
    openModal: () => dispatch(openUploadArtModal()),
    closeModal: () => dispatch(closeUploadArtModal()),
    // getArtWork: () => dispatch(fetchArtWork())
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     getArtStyleData: (data,id) => dispatch(fetchArtist(data,id))
//   }
// }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditComponent);
