/**
 * Global Config
 */

const config = {
  cognito: {
    USER_POOL_ID: "ap-south-1_6Tz4OLn4d",
    APP_CLIENT_ID: "6n3t3poh0co772eaahgvopcfn",
  },
  api: {
    NAME: "KALA_CUBE_LAMBDA_SERVER",
    endpoint: "https://1234567890-abcdefgh.amazonaws.com",
  },
  instagram: {
    CLIENT_ID: "1198985934241553",
    REDIRECT_URL: "https://www.kalacube.com/profile/instagram/verify/",
  },
  artSpaceCategories: [
    {
      id: "cafe/restaurant",
      name: "Café / Restaurant",
    },
    {
      id: "hotel",
      name: "Hotel",
    },
    {
      id: "art-gallery",
      name: "Art Gallery",
    },
    {
      id: "corporate",
      name: "Corporate",
    },
    {
      id: "club",
      name: "Club",
    },
  ],
  artistLevel: [
    {
      id: "beginner",
      name: "Beginner",
    },
    {
      id: "intermediate",
      name: "Intermediate",
    },
    {
      id: "advanced",
      name: "Advanced",
    },
    {
      id: "expert",
      name: "Expert",
    },
  ],
  confirm: [
    {
      id: true,
      name: "Yes",
    },
    {
      id: false,
      name: "No",
    },
  ],
};

// Domains
config.domains = {};

/**
 * API Domain
 * Add the domain from your serverless express.js back-end here.
 * This will enable your front-end to communicate with your back-end.
 * (e.g. 'https://api.mydomain.com' or 'https://091jafsl10.execute-api.us-east-1.amazonaws.com')
 */
// config.domains.api = 'https://7l9tp674ib.execute-api.us-east-1.amazonaws.com'

export default config;
