import { combineReducers } from "redux";
import artStyleReducer from "./ArtStyle/reducer";
import artistReducer from "./Artist/reducer";
import artWorkReducer from "./ArtWork/reducer";
import userReducer from "./User/reducer";
import popularArtistReducer from "./PopularArtist/reducer";
import publicProfileReducer from "./PublicProfile/reducer";
import AllArtistReducer from "./AllArtist/reducer";
import AdminReducer from "./Admin/reducer";
import registrationReducer from "./Registration/reducer";
import editProfileReducer from "./EditProfileModal/reducer";
import uploadArtReducer from "./UploadArtModal/reducer";
import submitArtWorkReducer from "./SubmitArtWorkModal/reducer";
import qrCodeModalReducer from "./QrCodeModal/reducer";
import instagramReducer from "./Instagram/reducer";
import alternateSpacesReducer from "./AlternateSpaces/reducer";
import authReducer from "./Auth/reducer";
import artWorkModalReducer from "./ArtWorkModal/reducer";
import userRoleReducer from "./UserRole/reducer";
import artStyleModalReducer from "./ArtStyleModal/reducer";

const rootReducer = combineReducers({
  artStyle: artStyleReducer,
  artist: artistReducer,
  artWork: artWorkReducer,
  user: userReducer,
  popArtist: popularArtistReducer,
  publicArtist: publicProfileReducer,
  allArtist: AllArtistReducer,
  admin: AdminReducer,
  registrationModal: registrationReducer,
  editProfileModal: editProfileReducer,
  uploadArtModal: uploadArtReducer,
  submitArtWorkModal: submitArtWorkReducer,
  qrCodeModal: qrCodeModalReducer,
  instagram: instagramReducer,
  alternateSpaces: alternateSpacesReducer,
  authReducer: authReducer,
  artWorkModal: artWorkModalReducer,
  userRoleReducer: userRoleReducer,
  artStyleModal: artStyleModalReducer,
});
export default rootReducer;
