import {
  INCREASE_STEP,
  DECREASE_STEP,
  OPEN_UPLOAD_ART_MODAL,
  CLOSE_UPLOAD_ART_MODAL,
} from "./type";

export const increaseStep = () => ({ type: INCREASE_STEP });

export const decreaseStep = () => ({ type: DECREASE_STEP });

export const openUploadArtModal = () => ({ type: OPEN_UPLOAD_ART_MODAL });

export const closeUploadArtModal = () => ({ type: CLOSE_UPLOAD_ART_MODAL });
