import { Button, Modal, Radio, Row, Tooltip, Slider, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import "./styles.css";
import { RotateRightOutlined } from "@ant-design/icons";
import { getCroppedImg } from "./utils";
import PreviewModal from "./PreviewModal";
import Resizer from "react-image-file-resizer";

const CropModal = ({
  cropModal,
  setCropModal,
  onCompleteCrop,
  discardImage,
  setFileList,
}) => {
  const { data } = cropModal;
  const [img, setImg] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspectRatio, setAspectRatio] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [previewModal, setPreviewModal] = useState({
    visible: false,
    data: null,
  });
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);

  useEffect(() => {
    Resizer.imageFileResizer(
      data.file,
      1920,
      1920,
      "JPEG",
      50,
      0,
      (compressedImage) => {
        const reader = new FileReader();
        reader.onload = () => setImg(reader.result);
        reader.readAsDataURL(compressedImage);
      },
      "file"
    );
  }, [data]);
  useEffect(() => {
    setTimeout(() => {
      setAspectRatio(16 / 9);
    }, 500);
  }, []);
  return (
    <Modal
      width={window.innerWidth > 768 ? "60%" : "95%"}
      title="Crop Image"
      visible={cropModal.visible}
      onCancel={() => {
        setCropModal((oldValue) => ({
          ...oldValue,
          visible: false,
        }));
      }}
      footer={null}
    >
      <Row
        style={{
          position: "relative",
          width: "100%",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {img ? (
          <Cropper
            image={img}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            aspect={aspectRatio}
            onCropComplete={(_, croppedAreaPixes) => {
              setCroppedAreaPixels(croppedAreaPixes);
            }}
          />
        ) : (
          <Spin />
        )}
      </Row>
      <Row align="middle" justify="center">
        <div
          style={{
            textAlign: "center",
            margin: "1rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Tooltip title="Rotate">
            <RotateRightOutlined
              onClick={() => setRotation((rotation + 90) % 360)}
              className="rotate-icon"
            />
          </Tooltip>
          <Radio.Group
            buttonStyle="solid"
            onChange={(event) => {
              setAspectRatio(event.target.value);
            }}
            size="large"
            value={aspectRatio}
          >
            <Radio.Button value={16 / 9}>Landscape</Radio.Button>
            <Radio.Button value={9 / 16}>Portrait</Radio.Button>
            <Radio.Button value={1 / 1}>Square</Radio.Button>
          </Radio.Group>
        </div>
      </Row>
      <Row
        align="middle"
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <p
          style={{ fontSize: "1.1rem", margin: 0, textTransform: "uppercase" }}
        >
          Zoom
        </p>
        <Slider
          style={{ width: "50%" }}
          min={1}
          max={3}
          step={0.1}
          value={zoom}
          onChange={setZoom}
        />
      </Row>
      <Row
        align="middle"
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <p
          style={{ fontSize: "1.1rem", margin: 0, textTransform: "uppercase" }}
        >
          Rotate
        </p>
        <Slider
          style={{ width: "50%" }}
          min={0}
          max={360}
          step={1}
          value={rotation}
          onChange={setRotation}
        />
      </Row>
      <Row justify="end">
        <Button
          type="primary"
          size="large"
          className="ml-2"
          loading={previewLoading}
          disabled={!img}
          onClick={async () => {
            setPreviewLoading(true);
            const image = await getCroppedImg(
              img,
              croppedAreaPixels,
              rotation,
              false
            );
            setPreviewLoading(false);
            setPreviewModal({
              visible: true,
              data: image,
            });
          }}
        >
          Preview
        </Button>
        <Button
          loading={loading}
          type="primary"
          size="large"
          className="ml-2"
          disabled={!img}
          onClick={async () => {
            setLoading(true);
            const blob = await getCroppedImg(img, croppedAreaPixels, rotation);
            const file = new File([blob], data.file.name, {
              type: "image/jpeg",
              lastModified: new Date(),
            });
            Resizer.imageFileResizer(
              file,
              1920,
              1920,
              "JPEG",
              50,
              0,
              (compressedImage) => {
                onCompleteCrop(
                  data,
                  compressedImage,
                  aspectRatio,
                  () => {
                    setLoading(false);
                    setCropModal({
                      visible: false,
                    });
                  },
                  () => {
                    setLoading(false);
                  }
                );
              },
              "file"
            );
          }}
        >
          Save
        </Button>
      </Row>
      {previewModal.visible && (
        <PreviewModal
          previewModal={previewModal}
          setPreviewModal={setPreviewModal}
        />
      )}
    </Modal>
  );
};

export default CropModal;
