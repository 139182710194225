export const FETCH_ARTIST_REQUEST = "FETCH_ARTIST_REQUEST";
export const FETCH_ARTIST_SUCCESS = "FETCH_ARTIST_SUCCESS";
export const FETCH_ARTIST_FAILURE = "FETCH_ARTIST_FAILURE";

export const POST_ARTIST_REQUEST = "POST_ARTIST_REQUEST";
export const POST_ARTIST_SUCCESS = "POST_ARTIST_SUCCESS";
export const POST_ARTIST_FAILURE = "POST_ARTIST_FAILURE";

export const DELETE_ARTIST_REQUEST = "DELETE_ARTIST_REQUEST";
export const DELETE_ARTIST_SUCCESS = "DELETE_ARTIST_SUCCESS";
export const DELETE_ARTIST_FAILURE = "DELETE_ARTIST_FAILURE";

export const UPDATE_ARTIST_REQUEST = "UPDATE_ARTIST_REQUEST";
export const UPDATE_ARTIST_SUCCESS = "UPDATE_ARTIST_SUCCESS";
export const UPDATE_ARTIST_FAILURE = "UPDATE_ARTIST_FAILURE";

export const USERNAME_ARTIST_REQUEST = "USERNAME_ARTIST_REQUEST";
export const USERNAME_ARTIST_SUCCESS = "USERNAME_ARTIST_SUCCESS";
export const USERNAME_ARTIST_FAILURE = "USERNAME_ARTIST_FAILURE";

export const CLEAR_STORE = "CLEAR_STORE";
