import { Modal } from "antd";
import React from "react";

const PreviewModal = ({ previewModal, setPreviewModal }) => {
  return (
    <Modal
      title="Preview"
      footer={null}
      width="60%"
      visible={previewModal.visible}
      onCancel={() =>
        setPreviewModal((oldValue) => ({
          ...oldValue,
          visible: false,
        }))
      }
    >
      <img
        style={{
          width: "100%",
          maxHeight: "500px",
          objectFit: "contain",
        }}
        src={previewModal.data}
        alt="art work"
      />
    </Modal>
  );
};

export default PreviewModal;
