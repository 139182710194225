import React from "react";
import { Row, Col, Card, Form, Input, Checkbox, Button } from "antd";
import {
  FormOutlined,
  SaveOutlined,
  DollarOutlined,
  FileOutlined,
  GroupOutlined,
  BorderOutlined,
  SwitcherOutlined,
  MediumOutlined,
  BgColorsOutlined,
  ColumnWidthOutlined,
  ColumnHeightOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { createArtWork } from "../../../../../Redux/ArtWork/action";
import { connect } from "react-redux";

const BasicArtForm = ({ basicForm, artistData, createArtWorkData }) => {
  // const [basicForm] = Form.useForm();

  const onFinish = (values) => {
    const artWorkData = { ...values, artistId: artistData.artist.id };

    createArtWorkData(artWorkData);
  };

  // useEffect(() => {
  //   if (artistData) {
  //     const data = artistData.user;
  //     if (data.dob) {
  //       delete data.dob;
  //     }
  //     basicForm.setFieldsValue(data);
  //   basicForm.setFieldsValue({dob : moment(artistData.user, true)})
  //   }
  // }, [])

  return (
    <Card className="w-100 mt-5" title="Basic Art Information">
      <Form form={basicForm} name="basic_info" onFinish={onFinish} size="large">
        <Row gutter={16}>
          <Col md={12} sm={24} flex="auto">
            <Form.Item name="title" rules={[{ required: true }]}>
              <Input
                prefix={<FormOutlined className="site-form-item-icon" />}
                type="string"
                placeholder="Title"
              />
            </Form.Item>
          </Col>
          <Col md={12} ms={24} flex={"auto"}>
            <Form.Item name="cost">
              <Input
                prefix="₹"
                min={0}
                type="number"
                step="1"
                placeholder="Cost of Art work"
              />
            </Form.Item>
          </Col>
          <Col md={12} ms={24} flex={"auto"}>
            <Form.Item name="material">
              <Input
                prefix={<GroupOutlined className="site-form-item-icon" />}
                type="string"
                placeholder="Material & other details"
              />
            </Form.Item>
          </Col>
          {/* <Col md={12} ms={24} flex={"auto"}>
            <Form.Item name="type" >
              <Input prefix={<GroupOutlined className="site-form-item-icon" />} type="string" placeholder="Type" />
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={16}>
          <Col md={12} ms={24} flex={"auto"}>
            <Form.Item name="medium">
              <Input
                prefix={<MediumOutlined className="site-form-item-icon" />}
                type="string"
                placeholder="Medium"
              />
            </Form.Item>
          </Col>
          <Col md={12} ms={24} flex={"auto"}>
            <Form.Item name="theme">
              <Input
                prefix={<BgColorsOutlined className="site-form-item-icon" />}
                type="string"
                placeholder="Theme"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* <Col md={12} sm={24} flex="auto">
            <Form.Item name="element" >
              <Input prefix={<SwitcherOutlined className="site-form-item-icon" />} type="string" placeholder="Element" />
            </Form.Item>
          </Col>         */}

          <Col md={12} sm={24} flex="auto">
            <Input.Group size="large" name="dimention">
              <Row gutter={8}>
                <Col span={6}>
                  {/* <Form.Item label="Dimensions (In Inch)">
              </Form.Item> */}
                  <label>Dimensions (In Inch)</label>
                </Col>
                <Col span={9}>
                  <Form.Item name="height">
                    <Input
                      prefix={
                        <ColumnHeightOutlined className="site-form-item-icon" />
                      }
                      min={0}
                      type="Number"
                      placeholder="Height"
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name="width">
                    <Input
                      prefix={
                        <ColumnWidthOutlined className="site-form-item-icon" />
                      }
                      min={0}
                      type="Number"
                      placeholder="Width"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={24} flex="auto">
            <Form.Item name="description">
              <TextArea
                prefix={<FileOutlined className="site-form-item-icon" />}
                row={4}
                placeholder="Description"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col md={8} ms={24} flex={"auto"}>
            <Form.Item
              valuePropName="checked"
              type="boolean"
              label="Available"
              name="available"
            >
              <Checkbox></Checkbox>
            </Form.Item>
            {/* <Form.Item label="Status" name="status">
              <Radio.Group size="large" >
                <Radio value={1} >Available</Radio>
                <Radio value={2}>Unavailable</Radio>

              </Radio.Group>
            </Form.Item> */}
          </Col>
          <Col md={8} ms={24} flex={"auto"}>
            <Form.Item
              valuePropName="checked"
              type="boolean"
              label="Copies"
              name="copy"
            >
              <Checkbox></Checkbox>
            </Form.Item>
          </Col>
          {/* <Col md={8} ms={24} flex={"auto"}>
            
            <Button loading={artistData.loading} size="large" style={{ float: 'right' }} type="link" shape="circle"  htmlType="submit" icon={<SaveOutlined />}/>
            
          </Col> */}
        </Row>
      </Form>
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    artistData: state.artist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createArtWorkData: (data) => dispatch(createArtWork(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicArtForm);
