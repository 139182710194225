import React from "react";
import { Input, Form, Radio, Button, Checkbox, Row, Col, Card } from "antd";
import Basic from "./Basic";
import ArtistStyles from "./ArtistStyles";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";

const BasicInformation = ({
  basicForm,
  setSelectedArtStyle,
  selectedArtStyle,
}) => {
  return (
    <Row className="pl-2 pr-2 welcome-screen-container mt-3">
      {/* <Col span={24} className="mb-20">
        <h1>Art Information</h1>
      </Col> */}
      <Col span={24} className="content-sec">
        <Row>
          <ArtistStyles
            selectedArtStyle={selectedArtStyle}
            setSelectedArtStyle={setSelectedArtStyle}
          />
          <Basic basicForm={basicForm} />
        </Row>
      </Col>
    </Row>
  );
};

export default BasicInformation;
