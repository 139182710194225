import { OPEN_ART_STYLE_MODAL, CLOSE_ART_STYLE_MODAL } from "./type";

export const openArtStyleModal = (payload) => (dispatch) => {
  dispatch({
    type: OPEN_ART_STYLE_MODAL,
    payload,
  });
};

export const closeArtStyleModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_ART_STYLE_MODAL,
  });
};
