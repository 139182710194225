import React, { useState } from "react";
import { Form, Input, Button, Popover } from "antd";
import { useHistory } from "react-router";
import { API, Auth } from "aws-amplify";
import { toast } from "react-toastify";
import * as S from "../styles";
const passwordValidator = require("password-validator");

const ForgotPasswordForm = () => {
  const [loading, setLoading] = React.useState(false);

  const [isConfirmationCodeSent, setIsConfirmationCodeSent] =
    React.useState(false);

  const history = useHistory();
  const [form] = Form.useForm();

  const navTo = (link) => {
    history.push(link);
  };

  const [userName, setUserName] = useState("");

  const title = <strong>Password Policy</strong>;
  const passwordPolicyContent = (
    <React.Fragment>
      <span>
        <strong> Your password should contain:</strong>
      </span>
      <ul>
        <li>Minimum length of 8 characters</li>
        <li>Numerical characters (0-9)</li>
        <li>Special characters</li>
        <li>Uppercase letter</li>
        <li>Lowercase letter</li>
      </ul>
    </React.Fragment>
  );

  const validateToNextPassword = (rule, value, callback) => {
    const validationRulesErrors = schema.validate(value, { list: true });

    if (validationRulesErrors.length > 0) {
      callback(formatPasswordValidateError(validationRulesErrors));
    }
    callback();
  };

  const formatPasswordValidateError = (errors) => {
    for (const error of errors) {
      if (error === "min") {
        return "password length should be a at least 8 characters";
      } else if (error === "lowercase") {
        return "password should contain lowercase letters";
      } else if (error === "uppercase") {
        return "password should contain uppercase letters";
      } else if (error === "digits") {
        return "password should contain digits";
      } else if (error === "symbols") {
        return "password should contain symbols";
      }
    }
  };

  const schema = new passwordValidator();
  schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();

  const handleEmail = async (values) => {
    setLoading(true);

    if (!isConfirmationCodeSent) {
      API.get("backend-api", "/api/users/getUsername", {
        queryStringParameters: {
          email: values.email.toLowerCase().trim(),
        },
      })
        .then((res) => {
          if (res.data.length === 0) {
            toast.error("Please enter valid email.");
            setLoading(false);
          } else {
            setUserName(res.data[0].userName);
            Auth.forgotPassword(res.data[0].userName)
              .then((data) => {
                toast.success(
                  `Verification Code has been sent to ${data.CodeDeliveryDetails.Destination} ${data.CodeDeliveryDetails.DeliveryMedium}`
                );
                setIsConfirmationCodeSent(true);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                toast.error(err.message);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // Collect confirmation code and new password , then
      Auth.forgotPasswordSubmit(userName, values.code, values.new_password)
        .then((data) => {
          setLoading(false);
          toast.success(`Password changed successfully!!`);
          navTo("/registration/login");
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <S.LoginRight>
      <h1>Forgot Password ?</h1>
      <Form
        form={form}
        name="forgot-password"
        onFinish={handleEmail}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
            {
              type: "email",
              message: "The input is not valid Email!",
            },
          ]}
        >
          <Input
            className="login-right-input"
            placeholder="Email"
            type={"email"}
            size="large"
            disabled={isConfirmationCodeSent}
          />
        </Form.Item>

        {isConfirmationCodeSent && (
          <>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please input confirmation code!",
                },
                // {
                //     type: "email",
                //     message: "The input is not valid User Name!"
                // }
              ]}
            >
              <Input
                className="login-right-input"
                placeholder="Confirmation Code"
                size="large"
              />
            </Form.Item>

            <Popover
              placement="right"
              title={title}
              content={passwordPolicyContent}
              trigger="focus"
            >
              <Form.Item
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please input New Password!",
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ]}
              >
                <Input.Password
                  className="login-right-input"
                  placeholder="New Password"
                  size="large"
                />
              </Form.Item>
            </Popover>
          </>
        )}

        <Form.Item shouldUpdate className="text-center mt-4">
          {() => (
            <Button
              loading={loading}
              className="login-right-button"
              style={{ borderRadius: "4px" }}
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </S.LoginRight>
  );
};
export default ForgotPasswordForm;
