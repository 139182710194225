import {
  FETCH_ALL_ARTIST_ADMIN_REQUEST,
  FETCH_ALL_ARTIST_ADMIN_SUCCESS,
  FETCH_ALL_ARTIST_ADMIN_FAILURE,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
  GET_CONFIG_REQUEST,
  UPDATE_POPULAR_ARTIST_ADMIN_REQUEST,
  UPDATE_POPULAR_ARTIST_ADMIN_SUCCESS,
  UPDATE_POPULAR_ARTIST_ADMIN_FAILURE,
  GET_ART_OF_THE_WEEK_CONFIG_REQUEST,
  GET_ART_OF_THE_WEEK_CONFIG_SUCCESS,
  GET_ART_OF_THE_WEEK_CONFIG_FAILURE,
  GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_REQUEST,
  GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_SUCCESS,
  GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_FAILURE,
  GET_PHOTO_GALLERY_CONFIG_REQUEST,
  GET_PHOTO_GALLERY_CONFIG_SUCCESS,
  GET_PHOTO_GALLERY_CONFIG_FAILURE,
  UPDATE_ART_OF_THE_WEEK_ADMIN_REQUEST,
  UPDATE_ART_OF_THE_WEEK_ADMIN_SUCCESS,
  UPDATE_ART_OF_THE_WEEK_ADMIN_FAILURE,
} from "./type";

const initialState = {
  loading: false,
  allArtist: undefined,
  artOfTheWeek: {},
  highLightsOfTheWeek: [],
  photoGallery: [],
  error: "",
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_ARTIST_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_ARTIST_ADMIN_SUCCESS:
      return {
        loading: false,
        allArtist: action.payload,
        error: "",
        success: action.success,
      };
    case FETCH_ALL_ARTIST_ADMIN_FAILURE:
      return {
        loading: false,
        allArtist: undefined,
        error: action.payload,
      };

    case UPDATE_POPULAR_ARTIST_ADMIN_REQUEST:
      return {
        ...state,
        saveLoading: true,
      };
    case UPDATE_POPULAR_ARTIST_ADMIN_SUCCESS:
      return {
        ...state,
        saveLoading: false,
        error: "",
      };
    case UPDATE_POPULAR_ARTIST_ADMIN_FAILURE:
      return {
        ...state,
        saveLoading: false,
        allArtist: undefined,
        error: action.payload,
      };

    case GET_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedArtist: action.payload,
        error: "",
        success: action.success,
      };
    case GET_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        allArtist: undefined,
        error: action.payload,
      };
    case GET_ART_OF_THE_WEEK_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ART_OF_THE_WEEK_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        artOfTheWeek: action.payload,
        error: "",
        success: action.success,
      };
    case GET_ART_OF_THE_WEEK_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        artOfTheWeek: {},
        error: action.payload,
      };
    case GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        highLightsOfTheWeek: action.payload,
        error: "",
        success: action.success,
      };
    case GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        highLightsOfTheWeek: [],
        error: action.payload,
      };
    case GET_PHOTO_GALLERY_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PHOTO_GALLERY_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        photoGallery: action.payload,
        error: "",
        success: action.success,
      };
    case GET_PHOTO_GALLERY_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        photoGallery: [],
        error: action.payload,
      };
    case UPDATE_ART_OF_THE_WEEK_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        artOfTheWeek: {},
        error: null,
      };
    case UPDATE_ART_OF_THE_WEEK_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        artOfTheWeek: action.payload,
        error: null,
      };
    case UPDATE_ART_OF_THE_WEEK_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        artOfTheWeek: {},
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
