import { FacebookOutlined, GoogleOutlined } from "@ant-design/icons";
import { Form, Input, Button, Radio } from "antd";
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useAppContext } from "../../../common/ContextLib";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { connect } from "react-redux";
import { fetchUser } from "../../../Redux/User/action";
import {
  fetchArtist,
  fetchArtistByUsername,
} from "../../../Redux/Artist/action";
import { toast } from "react-toastify";
import { fetchArtWork } from "../../../Redux/ArtWork/action";
import {
  closeRegistrationModal,
  fetchArtStyles,
  getUserDetails,
} from "../../../Redux";
import ForgotPasswordForm from "../ForgotPassword";
import { Switch, Route, Redirect } from "react-router-dom";
import * as S from "../styles";
import store from "../../../Redux/store";
import { openRegistrationModal } from "../../../Redux";
import constants from "../../../constants";

const LoginForm = ({
  userData,
  getUser,
  artistData,
  getArtist,
  getArtWork,
  artWrokData,
  getArtStyles,
  getUsername,
}) => {
  const { userHasAuthenticated, isAuthenticated, setAccessToken } =
    useAppContext();

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({}); //for enable and disable

  useEffect(() => {
    forceUpdate({});
  }, []);

  const toastr = () => {
    toast.success("your Welcome", {
      position: "top-center",
    });
  };

  const history = useHistory();
  const [passwordForm, setPasswordForm] = React.useState("login");

  const navTo = (link) => {
    history.push(link);
  };

  useEffect(() => {
    //
    if (artistData.artist && isAuthenticated) {
      setLoading(false);
      // navTo("/");
    }
  }, [artistData]);

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);

    getUsername(
      values.username.trim(),
      (username) => {
        Auth.signIn(username, values.password)
          .then((user) => {
            setAccessToken(user.signInUserSession.accessToken.jwtToken);

            userHasAuthenticated(true);

            getArtist();
            // getArtStyles();
            toast.success("Login Successful");
            store.dispatch(closeRegistrationModal());
            setLoading(false);
          })
          .catch((e) => {
            if (e.code === "UserNotConfirmedException") {
              toast.info("Please Verify Your Email Id.");
            } else {
              toast.error(e.message);
            }
            setLoading(false);
          });
      },
      (error) => {
        toast.error(error);
        setLoading(false);
      }
    );
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <S.LoginRight>
      <h1>Sign in</h1>
      <Form
        form={form}
        name="login"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your User Name",
            },
          ]}
        >
          <Input
            className="login-right-input"
            placeholder="User Name"
            size="large"
            style={{ textTransform: "lowercase" }}
          />
        </Form.Item>

        <Form.Item
          name="password"
          className="mb-0"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            className="login-right-input "
            placeholder="Password"
            size="large"
          />
        </Form.Item>
        <Form.Item className="text-right  ">
          <div
            className="login-form-forgot "
            onClick={() =>
              store.dispatch(
                openRegistrationModal(
                  constants.REGISTRATION_TYPE.FORGOT_PASSWORD
                )
              )
            }
          >
            Forgot password
          </div>
        </Form.Item>
        <Form.Item shouldUpdate className="text-center">
          {() => (
            <Button
              loading={loading}
              className="login-right-button"
              style={{ borderRadius: "4px" }}
              size="large"
              shape="round"
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Submit
            </Button>
          )}
        </Form.Item>
        <div className="switch-button text-center">
          <p>
            New to KalaCube ?{" "}
            <Button
              type="link"
              size="large"
              onClick={() =>
                store.dispatch(
                  openRegistrationModal(constants.REGISTRATION_TYPE.SIGN_UP)
                )
              }
            >
              Join now
            </Button>
          </p>
        </div>
      </Form>

      <div>
        <p>or Sign in with social Platform</p>
        <Button
          type={"link"}
          icon={<FacebookOutlined style={{ fontSize: "26px" }} />}
          disabled
        />
        <Button
          className="ml-2"
          type={"link"}
          icon={<GoogleOutlined style={{ fontSize: "26px" }} />}
          disabled
        />
      </div>
    </S.LoginRight>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user,
    artistData: state.artist,
    artWrokData: state.artWork,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(fetchUser()),
    getArtist: () => dispatch(fetchArtist()),
    getArtStyles: () => dispatch(fetchArtStyles()),
    getUsername: (username, onSuccess, onError) =>
      dispatch(getUserDetails(username, onSuccess, onError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
