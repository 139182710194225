export const FETCH_ARTIST_ALL_ARTWORK_REQUEST =
  "FETCH_ARTIST_ALL_ARTWORK_REQUEST";
export const FETCH_ARTIST_ALL_ARTWORK_SUCCESS =
  "FETCH_ARTIST_ALL_ARTWORK_SUCCESS";
export const FETCH_ARTIST_ALL_ARTWORK_FAILURE =
  "FETCH_ARTIST_ALL_ARTWORK_FAILURE";

export const FETCH_ARTWORK_REQUEST = "FETCH_ARTWORK_REQUEST";
export const FETCH_ARTWORK_SUCCESS = "FETCH_ARTWORK_SUCCESS";
export const FETCH_ARTWORK_FAILURE = "FETCH_ARTWORK_FAILURE";

export const POST_ARTWORK_SUCCESS = "POST_ARTWORK_SUCCESS";
export const POST_ARTWORK_REQUEST = "POST_ARTWORK_REQUEST";
export const POST_ARTWORK_FAILURE = "POST_ARTWORK_FAILURE";

export const DELETE_ARTWORK_REQUEST = "DELETE_ARTWORK_REQUEST";
export const DELETE_ARTWORK_SUCCESS = "DELETE_ARTWORK_SUCCESS";
export const DELETE_ARTWORK_FAILURE = "DELETE_ARTWORK_FAILURE";

export const UPDATE_ARTWORK_REQUEST = "UPDATE_ARTWORK_REQUEST";
export const UPDATE_ARTWORK_SUCCESS = "UPDATE_ARTWORK_SUCCESS";
export const UPDATE_ARTWORK_FAILURE = "UPDATE_ARTWORK_FAILURE";

export const USERID_ARTWORK_REQUEST = "USERID_ARTWORK_REQUEST";
export const USERID_ARTWORK_SUCCESS = "USERID_ARTWORK_SUCCESS";
export const USERID_ARTWORK_FAILURE = "USERID_ARTWORK_FAILURE";

export const PUBLIC_ARTWORK_REQUEST = "PUBLIC_ARTWORK_REQUEST";
export const PUBLIC_ARTWORK_SUCCESS = "PUBLIC_ARTWORK_SUCCESS";
export const PUBLIC_ARTWORK_FAILURE = "PUBLIC_ARTWORK_FAILURE";

export const SET_SELECTED_ART_WORK = "SET_SELECTED_ART_WORK";

export const FETCH_RELATED_ARTWORK_REQUEST = "FETCH_RELATED_ARTWORK_REQUEST";
export const FETCH_RELATED_ARTWORK_SUCCESS = "FETCH_RELATED_ARTWORK_SUCCESS";
export const FETCH_RELATED_ARTWORK_FAILURE = "FETCH_RELATED_ARTWORK_FAILURE";

export const FETCH_ALL_ARTWORK_REQUEST = "FETCH_ALL_ARTWORK_REQUEST";
export const FETCH_ALL_ARTWORK_SUCCESS = "FETCH_ALL_ARTWORK_SUCCESS";
export const FETCH_ALL_ARTWORK_FAILURE = "FETCH_ALL_ARTWORK_FAILURE";

export const GET_ALL_ARTWORK_CHUNK_REQUEST = "GET_ALL_ARTWORK_CHUNK_REQUEST";
export const GET_ALL_ARTWORK_CHUNK_SUCCESS = "GET_ALL_ARTWORK_CHUNK_SUCCESS";
export const GET_ALL_ARTWORK_CHUNK_FAILURE = "GET_ALL_ARTWORK_CHUNK_FAILURE";

export const GET_RECENT_ARTWORK_REQUEST = "GET_RECENT_ARTWORK_REQUEST";
export const GET_RECENT_ARTWORK_SUCCESS = "GET_RECENT_ARTWORK_SUCCESS";
export const GET_RECENT_ARTWORK_FAILURE = "GET_RECENT_ARTWORK_FAILURE";

export const FETCH_TAGS_REQUEST = "FETCH_TAGS_REQUEST";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";

export const CREATE_TAG_REQUEST = "CREATE_TAG_REQUEST";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILURE = "CREATE_TAG_FAILURE";

export const DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILURE = "DELETE_TAG_FAILURE";

export const CLEAR_STORE = "CLEAR_STORE";
