import {
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
} from "./type";

const initialState = {
  userDetails: {
    loading: false,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetails: {
          loading: true,
          data: null,
        },
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        userDetails: {
          loading: false,
          data: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
