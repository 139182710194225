import {
  UNLINK_INSTAGRAM_FAILURE,
  UNLINK_INSTAGRAM_REQUEST,
  UNLINK_INSTAGRAM_SUCCESS,
  LINK_INSTAGRAM_FAILURE,
  LINK_INSTAGRAM_REQUEST,
  LINK_INSTAGRAM_SUCCESS,
  FETCH_INSTAGRAM_POSTS_REQUEST,
  FETCH_INSTAGRAM_POSTS_SUCCESS,
  FETCH_INSTAGRAM_POSTS_FAILURE,
} from "./type";

const initialState = {
  linkInstagram: false,
  unlinkInstagram: false,
  fetchInstagramPosts: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LINK_INSTAGRAM_REQUEST:
      return {
        ...state,
        linkInstagram: true,
      };
    case LINK_INSTAGRAM_SUCCESS:
      return {
        ...state,
        linkInstagram: false,
      };
    case LINK_INSTAGRAM_FAILURE:
      return {
        ...state,
        linkInstagram: false,
      };
    case UNLINK_INSTAGRAM_REQUEST:
      return {
        ...state,
        unlinkInstagram: true,
      };
    case UNLINK_INSTAGRAM_SUCCESS:
      return {
        ...state,
        unlinkInstagram: false,
      };
    case UNLINK_INSTAGRAM_FAILURE:
      return {
        ...state,
        fetchInstagramPosts: false,
      };
    case FETCH_INSTAGRAM_POSTS_REQUEST:
      return {
        ...state,
        fetchInstagramPosts: true,
      };
    case FETCH_INSTAGRAM_POSTS_SUCCESS:
      return {
        ...state,
        fetchInstagramPosts: false,
      };
    case FETCH_INSTAGRAM_POSTS_FAILURE:
      return {
        ...state,
        unlinkInstagram: false,
      };
    default:
      return { ...state };
  }
};

export default reducer;
