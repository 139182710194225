import {
  CREATE_ALT_SPACE_FAILURE,
  CREATE_ALT_SPACE_REQUEST,
  CREATE_ALT_SPACE_SUCCESS,
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  DELETE_ALT_SPACE_FAILURE,
  DELETE_ALT_SPACE_REQUEST,
  DELETE_ALT_SPACE_SUCCESS,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  GET_ALL_ALT_SPACES_FAILURE,
  GET_ALL_ALT_SPACES_REQUEST,
  GET_ALL_ALT_SPACES_SUCCESS,
  GET_ALT_SPACE_DROPDOWN_FAILURE,
  GET_ALT_SPACE_DROPDOWN_REQUEST,
  GET_ALT_SPACE_DROPDOWN_SUCCESS,
  GET_ALT_SPACE_FAILURE,
  GET_ALT_SPACE_REQUEST,
  GET_ALT_SPACE_SUCCESS,
  UPDATE_ALT_SPACE_FAILURE,
  UPDATE_ALT_SPACE_REQUEST,
  UPDATE_ALT_SPACE_SUCCESS,
  UPDATE_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
} from "./type";

const initialState = {
  allAltSpaces: {
    loading: false,
    data: null,
  },
  createAltSpace: false,
  updateAltSpace: false,
  deleteAltSpace: false,
  getAltSpace: {
    loading: false,
    data: null,
  },
  createEvent: false,
  fetchAlternateSpaceDropdown: {
    loading: false,
    data: [],
  },
  deleteEvent: false,
  updateEvent: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ALT_SPACES_REQUEST:
      return {
        ...state,
        allAltSpaces: {
          loading: true,
          data: null,
        },
      };
    case GET_ALL_ALT_SPACES_SUCCESS:
      return {
        ...state,
        allAltSpaces: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_ALL_ALT_SPACES_FAILURE:
      return {
        ...state,
        allAltSpaces: {
          loading: false,
          data: null,
        },
      };
    case CREATE_ALT_SPACE_REQUEST:
      return {
        ...state,
        createAltSpace: true,
      };
    case CREATE_ALT_SPACE_SUCCESS:
    case CREATE_ALT_SPACE_FAILURE:
      return {
        ...state,
        createAltSpace: false,
      };
    case UPDATE_ALT_SPACE_REQUEST:
      return {
        ...state,
        updateAltSpace: true,
      };
    case UPDATE_ALT_SPACE_SUCCESS:
    case UPDATE_ALT_SPACE_FAILURE:
      return {
        ...state,
        updateAltSpace: false,
      };
    case DELETE_ALT_SPACE_REQUEST:
      return {
        ...state,
        deleteAltSpace: true,
      };
    case DELETE_ALT_SPACE_SUCCESS:
    case DELETE_ALT_SPACE_FAILURE:
      return {
        ...state,
        deleteAltSpace: false,
      };
    case GET_ALT_SPACE_REQUEST:
      return {
        ...state,
        getAltSpace: {
          loading: true,
          data: null,
        },
      };
    case GET_ALT_SPACE_SUCCESS:
      return {
        ...state,
        getAltSpace: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_ALT_SPACE_FAILURE:
      return {
        ...state,
        getAltSpace: {
          loading: false,
          data: null,
        },
      };
    case CREATE_EVENT_REQUEST:
      return {
        ...state,
        createEvent: true,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        createEvent: false,
        getAltSpace: {
          ...state.getAltSpace,
          events: [...state.getAltSpace.data.events, action.payload],
        },
      };
    case CREATE_EVENT_FAILURE:
      return {
        ...state,
        createEvent: false,
      };
    case GET_ALT_SPACE_DROPDOWN_REQUEST:
      return {
        ...state,
        fetchAlternateSpaceDropdown: {
          loading: true,
          data: [],
        },
      };
    case GET_ALT_SPACE_DROPDOWN_SUCCESS:
      return {
        ...state,
        fetchAlternateSpaceDropdown: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_ALT_SPACE_DROPDOWN_FAILURE:
      return {
        ...state,
        fetchAlternateSpaceDropdown: {
          loading: false,
          data: [],
        },
      };
    case DELETE_EVENT_REQUEST:
      return {
        ...state,
        deleteEvent: true,
      };
    case DELETE_EVENT_SUCCESS:
    case DELETE_EVENT_FAILURE:
      return {
        ...state,
        deleteEvent: false,
      };
    case UPDATE_EVENT_REQUEST:
      return {
        ...state,
        updateEvent: true,
      };
    case UPDATE_EVENT_SUCCESS:
    case UPDATE_EVENT_FAILURE:
      return {
        ...state,
        updateEvent: false,
      };
    default:
      return state;
  }
};

export default reducer;
