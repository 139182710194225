import { OPEN_REGISTRATION_MODAL, CLOSE_REGISTRATION_MODAL } from "./type";

const initialState = {
  visible: false,
  type: "",
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_REGISTRATION_MODAL:
      return {
        ...state,
        visible: true,
        type: action.payload,
      };
    case CLOSE_REGISTRATION_MODAL:
      return {
        ...state,
        visible: false,
        type: "",
      };
    default:
      return state;
  }
};

export default registrationReducer;
