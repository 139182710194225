import { Button, Modal } from "antd";
import React from "react";
import "./styles.css";
import * as S from "./styles";
import SvgIcon from "../../common/SvgIcon";
import LoginForm from "./LogIn";
import SignUpForm from "./SignUp";
import ForgotPasswordForm from "./ForgotPassword";
import { Switch, Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import store from "../../Redux/store";
import { closeRegistrationModal, openRegistrationModal } from "../../Redux";
import { useSelector } from "react-redux";
import constants from "../../constants";

const Registration = () => {
  const history = useHistory();
  const [form, setForm] = React.useState("login");
  const [width, setWidth] = React.useState(window.innerWidth);

  const registrationModal = useSelector((state) => state.registrationModal);

  React.useEffect(() => {
    window.onresize = () => setWidth(window.innerWidth);
    return () => {
      window.onresize = null;
    };
  }, []);

  return (
    <Modal
      title={
        registrationModal.type === "sign-up"
          ? "Sign Up"
          : registrationModal.type === "forgot-password"
          ? "Forgot Password"
          : "Sign In"
      }
      className="registration"
      width={Math.min(Number(width) - 30, 850)}
      footer={null}
      visible={registrationModal.visible}
      onCancel={() => store.dispatch(closeRegistrationModal())}
    >
      <S.LoginBackground>
        <S.LoginLeft>
          <SvgIcon src="Logo2.png" height="100px" />
          <h1>Are you an artist ?</h1>
          <p>Sign In to create your FREE public portfolio</p>
          <S.ButtonDiv>
            <Button
              shape="round"
              onClick={() =>
                store.dispatch(
                  openRegistrationModal(constants.REGISTRATION_TYPE.SIGN_UP)
                )
              }
            >
              Register
            </Button>
            <Button
              shape="round"
              onClick={() =>
                store.dispatch(
                  openRegistrationModal(constants.REGISTRATION_TYPE.LOGIN)
                )
              }
            >
              Sign In
            </Button>
          </S.ButtonDiv>
        </S.LoginLeft>

        {registrationModal.type === constants.REGISTRATION_TYPE.SIGN_UP ? (
          <SignUpForm />
        ) : registrationModal.type ===
          constants.REGISTRATION_TYPE.FORGOT_PASSWORD ? (
          <ForgotPasswordForm />
        ) : (
          <LoginForm />
        )}
      </S.LoginBackground>
    </Modal>
  );
};

export default Registration;
