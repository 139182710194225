import { API } from "aws-amplify";
import {
  FETCH_PUBLICARTIST_REQUEST,
  FETCH_PUBLICARTIST_SUCCESS,
  FETCH_PUBLICARTIST_FAILURE,
  FETCH_PUBLICARTWORK_REQUEST,
  FETCH_PUBLICARTWORK_SUCCESS,
  FETCH_PUBLICARTWORK_FAILURE,
} from "./type";

const apiName = "backend-api";
const path = "/api";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const fetchPublicArtist = (userName) => {
  myInit.queryStringParameters = { userName: userName };
  return (dispatch) => {
    dispatch(fetchPopularArtistRequest());
    API.get(apiName, `${path}/artists/getPublicArtistData`, myInit)
      .then((response) => {
        // response.data is the users
        const popularArtist = response.data;
        dispatch(fetchPopularArtistSuccess(popularArtist));

        dispatch(fetchArtWork(userName));
      })
      .catch((error) => {
        // error.message is the error message
        if (error.message) {
          dispatch(fetchPopularArtistFailure(error.message));
        } else {
          dispatch(fetchPopularArtistFailure(error.response.data.message));
        }
      });
  };
};

export const fetchArtWork = (userName) => {
  myInit.queryStringParameters = { userName: userName };
  return (dispatch) => {
    dispatch(fetchArtWorkRequest());
    API.get(apiName, `${path}/art-works/getPublicArtWork`, myInit)
      .then((response) => {
        // response.data is the users
        const artWork = response.data;
        dispatch(fetchPublicArtWorkSuccess(artWork));
      })
      .catch((error) => {
        // error.message is the error message
        if (error.message) {
          dispatch(fetchArtWorkFailure(error.message));
        } else {
          dispatch(fetchArtWorkFailure(error.response.data.message));
        }
      });
  };
};

export const fetchPopularArtistRequest = () => {
  return {
    type: FETCH_PUBLICARTIST_REQUEST,
  };
};

export const fetchPopularArtistSuccess = (artist) => {
  return {
    type: FETCH_PUBLICARTIST_SUCCESS,
    payload: artist,
    success: "succesful",
  };
};

export const fetchPopularArtistFailure = (error) => {
  return {
    type: FETCH_PUBLICARTIST_FAILURE,
    payload: error,
  };
};

export const fetchArtWorkRequest = () => {
  return {
    type: FETCH_PUBLICARTWORK_REQUEST,
  };
};

export const fetchPublicArtWorkSuccess = (artWork) => {
  return {
    type: FETCH_PUBLICARTWORK_SUCCESS,
    payload: artWork,
    success: "succesful",
  };
};

export const fetchArtWorkFailure = (error) => {
  return {
    type: FETCH_PUBLICARTWORK_FAILURE,
    payload: error,
  };
};
