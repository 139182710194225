import { API, Auth } from "aws-amplify";
import {
  FETCH_ARTIST_REQUEST,
  FETCH_ARTIST_SUCCESS,
  FETCH_ARTIST_FAILURE,
  UPDATE_ARTIST_REQUEST,
  UPDATE_ARTIST_SUCCESS,
  UPDATE_ARTIST_FAILURE,
  DELETE_ARTIST_REQUEST,
  DELETE_ARTIST_SUCCESS,
  DELETE_ARTIST_FAILURE,
  POST_ARTIST_REQUEST,
  POST_ARTIST_SUCCESS,
  POST_ARTIST_FAILURE,
  USERNAME_ARTIST_REQUEST,
  USERNAME_ARTIST_SUCCESS,
  USERNAME_ARTIST_FAILURE,
  CLEAR_STORE,
} from "./type";
import { fetchUserSuccess, updateUser } from "../User/action";
import { fetchArtWork } from "../ArtWork/action";
import { toast } from "react-toastify";

const apiName = "backend-api";
const path = "/api/artists";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const fetchArtist = () => {
  return (dispatch) => {
    dispatch(fetchArtistRequest());
    API.get(apiName, path, myInit)
      .then(async (response) => {
        // response.data is the users
        const artist = response.data;
        dispatch(fetchArtistSuccess(artist));
        dispatch(fetchUserSuccess(artist.userId));
        if (!artist.userId.identityId) {
          const credentials = await Auth.currentUserCredentials();
          dispatch(
            updateUser({
              identityId: credentials.identityId,
            })
          );
        }

        dispatch(fetchArtWork());
      })
      .catch((error) => {
        // error.message is the error message
        if (error.message) {
          dispatch(fetchArtistFailure(error.message));
        } else {
          dispatch(fetchArtistFailure(error.response.data.message));
          toast.error("Unsuccessful");
        }
      });
  };
};

export const createArtist = (data) => {
  myInit.body = data;
  return (dispatch) => {
    dispatch(postArtistRequest());
    API.post(apiName, path, myInit)
      .then((response) => {
        // console.log(response)
        // response.data is the users
        // const user = response.data
        dispatch(postArtistSuccess([]));
        // toast.success("Successful")
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(postArtistFailure(error.response.data.message));
        toast.error("Unsuccessful");
      });
  };
};

export const deleteArtist = (id) => {
  return (dispatch) => {
    dispatch(deleteArtistRequest());
    API.del(apiName, path + `/${id}`)
      .then((response) => {
        // response.data is the users
        const user = response.data.data;
        dispatch(deleteArtistSuccess(user));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(deleteArtistFailure(error.response.data.message));
      });
  };
};

export const updateArtist = (data, id, isAdmin, onSuccess, onError) => {
  myInit.body = data;
  return (dispatch) => {
    dispatch(updateArtistRequest());
    let init = {
      ...myInit,
    };
    if (isAdmin) {
      init["queryStringParameters"] = {
        id: id,
      };
    }
    API.put(apiName, isAdmin ? `${path}/updateArtistByAdmin` : path, init)
      .then((response) => {
        // response.data is the users
        const artist = response.data;
        dispatch(updateArtistSuccess(artist));
        toast.success("Updated Successfuly");
        onSuccess && onSuccess();
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(updateArtistFailure(error.response.data.message));
        toast.error("Unsuccessful");
        onError && onError();
      });
  };
};

export const fetchArtistByUsername = (username) => {
  return (dispatch) => {
    dispatch(fetchArtistByUsernameRequest());
    API.get(apiName, path + `/username/${username}`, myInit)
      .then((response) => {
        // response.data is the users
        const artist = response.data;
        dispatch(fetchArtistByUsernameSuccess(artist));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchArtistByUsernameFailure(error.response.data.message));
      });
  };
};

export const postArtistRequest = () => {
  return {
    type: POST_ARTIST_REQUEST,
  };
};

export const postArtistSuccess = (user) => {
  return {
    type: POST_ARTIST_SUCCESS,
    payload: user,
    success: "succesful",
  };
};

export const postArtistFailure = (error) => {
  return {
    type: POST_ARTIST_FAILURE,
    payload: error,
  };
};

//DELETE REQUEST

export const deleteArtistRequest = () => {
  return {
    type: DELETE_ARTIST_REQUEST,
  };
};

export const deleteArtistSuccess = (user) => {
  return {
    type: DELETE_ARTIST_SUCCESS,
    payload: user,
    success: "succesfully deleted",
  };
};

export const deleteArtistFailure = (error) => {
  return {
    type: DELETE_ARTIST_FAILURE,
    payload: error,
  };
};

//update rquest

export const updateArtistRequest = () => {
  return {
    type: UPDATE_ARTIST_REQUEST,
  };
};

export const updateArtistSuccess = (user) => {
  return {
    type: UPDATE_ARTIST_SUCCESS,
    payload: user,
    success: "succesfully UPDATED",
  };
};

export const updateArtistFailure = (error) => {
  return {
    type: UPDATE_ARTIST_FAILURE,
    payload: error,
  };
};

export const fetchArtistRequest = () => {
  return {
    type: FETCH_ARTIST_REQUEST,
  };
};

export const fetchArtistSuccess = (artist) => {
  return {
    type: FETCH_ARTIST_SUCCESS,
    payload: artist,
  };
};

export const fetchArtistFailure = (error) => {
  return {
    type: FETCH_ARTIST_FAILURE,
    payload: error,
  };
};

export const fetchArtistByUsernameRequest = () => {
  return {
    type: USERNAME_ARTIST_REQUEST,
  };
};

export const fetchArtistByUsernameSuccess = (artist) => {
  return {
    type: USERNAME_ARTIST_SUCCESS,
    payload: artist,
  };
};

export const fetchArtistByUsernameFailure = (error) => {
  return {
    type: USERNAME_ARTIST_FAILURE,
    payload: error,
  };
};

// Request for clear data in redux

export const clearArtist = () => {
  return {
    type: CLEAR_STORE,
  };
};
