export const GET_ALL_ALT_SPACES_REQUEST = "GET_ALL_ALT_SPACES_REQUEST";
export const GET_ALL_ALT_SPACES_SUCCESS = "GET_ALL_ALT_SPACES_SUCCESS";
export const GET_ALL_ALT_SPACES_FAILURE = "GET_ALL_ALT_SPACES_FAILURE";

export const CREATE_ALT_SPACE_REQUEST = "CREATE_ALT_SPACE_REQUEST";
export const CREATE_ALT_SPACE_SUCCESS = "CREATE_ALT_SPACE_SUCCESS";
export const CREATE_ALT_SPACE_FAILURE = "CREATE_ALT_SPACE_FAILURE";

export const UPDATE_ALT_SPACE_REQUEST = "UPDATE_ALT_SPACE_REQUEST";
export const UPDATE_ALT_SPACE_SUCCESS = "UPDATE_ALT_SPACE_SUCCESS";
export const UPDATE_ALT_SPACE_FAILURE = "UPDATE_ALT_SPACE_FAILURE";

export const DELETE_ALT_SPACE_REQUEST = "DELETE_ALT_SPACE_REQUEST";
export const DELETE_ALT_SPACE_SUCCESS = "DELETE_ALT_SPACE_SUCCESS";
export const DELETE_ALT_SPACE_FAILURE = "DELETE_ALT_SPACE_FAILURE";

export const GET_ALT_SPACE_REQUEST = "GET_ALT_SPACE_REQUEST";
export const GET_ALT_SPACE_SUCCESS = "GET_ALT_SPACE_SUCCESS";
export const GET_ALT_SPACE_FAILURE = "GET_ALT_SPACE_FAILURE";

export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";

export const GET_ALT_SPACE_DROPDOWN_REQUEST = "GET_ALT_SPACE_DROPDOWN_REQUEST";
export const GET_ALT_SPACE_DROPDOWN_SUCCESS = "GET_ALT_SPACE_DROPDOWN_SUCCESS";
export const GET_ALT_SPACE_DROPDOWN_FAILURE = "GET_ALT_SPACE_DROPDOWN_FAILURE";

export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";
