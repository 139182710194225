import { API } from "aws-amplify";
import {
  FETCH_ALL_ARTIST_ADMIN_REQUEST,
  FETCH_ALL_ARTIST_ADMIN_SUCCESS,
  FETCH_ALL_ARTIST_ADMIN_FAILURE,
  CLEAR_STORE,
  UPDATE_POPULAR_ARTIST_ADMIN_REQUEST,
  UPDATE_POPULAR_ARTIST_ADMIN_SUCCESS,
  UPDATE_POPULAR_ARTIST_ADMIN_FAILURE,
  GET_CONFIG_REQUEST,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
  GET_ART_OF_THE_WEEK_CONFIG_REQUEST,
  GET_ART_OF_THE_WEEK_CONFIG_SUCCESS,
  GET_ART_OF_THE_WEEK_CONFIG_FAILURE,
  GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_REQUEST,
  GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_SUCCESS,
  GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_FAILURE,
  GET_PHOTO_GALLERY_CONFIG_REQUEST,
  GET_PHOTO_GALLERY_CONFIG_SUCCESS,
  GET_PHOTO_GALLERY_CONFIG_FAILURE,
  UPDATE_ART_OF_THE_WEEK_ADMIN_REQUEST,
  UPDATE_ART_OF_THE_WEEK_ADMIN_SUCCESS,
  UPDATE_ART_OF_THE_WEEK_ADMIN_FAILURE,
} from "./type";
import { toast } from "react-toastify";

const apiName = "backend-api";
const path = "/api/admins";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const fetchAllArtist = () => {
  return (dispatch) => {
    dispatch(fetchAllArtistRequest());
    API.get(apiName, path + `/all-artist`, myInit)
      .then((response) => {
        // response.data is the users
        const artist = response.data;
        dispatch(fetchAllArtistSuccess(artist));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchAllArtistFailure(error.response.data.message));
      });
  };
};

export const updatePopularArtist = (data) => {
  return (dispatch) => {
    myInit.body = data;

    dispatch(updatePopularArtistRequest());
    API.put(apiName, path, myInit)
      .then((response) => {
        dispatch(updatePopularArtistSuccess());
        toast.success("Featured artist data saved successfully.");
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(updatePopularArtistFailure(error.response.data.message));
      });
  };
};

export const getConfigByType = (type) => {
  return (dispatch) => {
    myInit["queryStringParameters"] = {
      type: type,
    };

    dispatch(getConfigDataRequest());
    API.get(apiName, path + `/config`, myInit)
      .then((response) => {
        // response.data is the users
        const artist = response.data;
        dispatch(getConfigDataSuccess(artist));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(getConfigDataFailure(error.response.data.message));
      });
  };
};

export const getArtOfTheWeekConfig = () => {
  return (dispatch) => {
    myInit["queryStringParameters"] = {
      type: "art-of-the-week",
    };

    dispatch(getArtOfTheWeekConfigRequest());
    API.get(apiName, path + `/get-ui-config`, myInit)
      .then((response) => {
        // response.data is the users
        const config = response.data.data[0];
        dispatch(getArtOfTheWeekConfigSuccess(config));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(getArtOfTheWeekConfigFailure(error.response.data.message));
      });
  };
};

export const getHighlightsOfTheWeekConfig = () => {
  return (dispatch) => {
    myInit["queryStringParameters"] = {
      type: "highlights-of-the-week",
    };

    dispatch(getHighlightsOfTheWeekConfigRequest());
    API.get(apiName, path + `/config`, myInit)
      .then((response) => {
        // response.data is the users
        const config = response.data;
        dispatch(getHighlightsOfTheWeekConfigSuccess(config));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(
          getHighlightsOfTheWeekConfigFailure(error.response.data.message)
        );
      });
  };
};

export const getPhotoGalleryConfig = () => {
  return (dispatch) => {
    myInit["queryStringParameters"] = {
      type: "photo-gallery",
    };

    dispatch(getPhotoGalleryConfigRequest());
    API.get(apiName, path + `/config`, myInit)
      .then((response) => {
        // response.data is the users
        const config = response.data;
        dispatch(getPhotoGalleryConfigSuccess(config));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(getPhotoGalleryConfigFailure(error.response.data.message));
      });
  };
};

export const fetchAllArtistRequest = () => {
  return {
    type: FETCH_ALL_ARTIST_ADMIN_REQUEST,
  };
};

export const fetchAllArtistSuccess = (artist) => {
  return {
    type: FETCH_ALL_ARTIST_ADMIN_SUCCESS,
    payload: artist,
  };
};

export const fetchAllArtistFailure = (error) => {
  return {
    type: FETCH_ALL_ARTIST_ADMIN_FAILURE,
    payload: error,
  };
};

export const updatePopularArtistRequest = () => {
  return {
    type: UPDATE_POPULAR_ARTIST_ADMIN_REQUEST,
  };
};

export const updatePopularArtistSuccess = () => {
  return {
    type: UPDATE_POPULAR_ARTIST_ADMIN_SUCCESS,
  };
};

export const updatePopularArtistFailure = (error) => {
  return {
    type: UPDATE_POPULAR_ARTIST_ADMIN_FAILURE,
    payload: error,
  };
};

export const getConfigDataRequest = () => {
  return {
    type: GET_CONFIG_REQUEST,
  };
};

export const getConfigDataSuccess = (data) => {
  return {
    type: GET_CONFIG_SUCCESS,
    payload: data,
  };
};

export const getConfigDataFailure = (error) => {
  return {
    type: GET_CONFIG_FAILURE,
    payload: error,
  };
};

export const getArtOfTheWeekConfigRequest = () => {
  return {
    type: GET_ART_OF_THE_WEEK_CONFIG_REQUEST,
  };
};

export const getArtOfTheWeekConfigSuccess = (data) => {
  return {
    type: GET_ART_OF_THE_WEEK_CONFIG_SUCCESS,
    payload: data,
  };
};

export const getArtOfTheWeekConfigFailure = (error) => {
  return {
    type: GET_CONFIG_FAILURE,
    payload: error,
  };
};

export const getHighlightsOfTheWeekConfigRequest = () => {
  return {
    type: GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_REQUEST,
  };
};

export const getHighlightsOfTheWeekConfigSuccess = (data) => {
  return {
    type: GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_SUCCESS,
    payload: data,
  };
};

export const getHighlightsOfTheWeekConfigFailure = (error) => {
  return {
    type: GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_FAILURE,
    payload: error,
  };
};

export const getPhotoGalleryConfigRequest = () => {
  return {
    type: GET_PHOTO_GALLERY_CONFIG_REQUEST,
  };
};

export const getPhotoGalleryConfigSuccess = (data) => {
  return {
    type: GET_PHOTO_GALLERY_CONFIG_SUCCESS,
    payload: data,
  };
};

export const getPhotoGalleryConfigFailure = (error) => {
  return {
    type: GET_PHOTO_GALLERY_CONFIG_FAILURE,
    payload: error,
  };
};

export const updateArtOfTheWeekRequest = () => {
  return {
    type: UPDATE_ART_OF_THE_WEEK_ADMIN_REQUEST,
  };
};

export const updateArtOfTheWeekSuccess = () => {
  return {
    type: UPDATE_ART_OF_THE_WEEK_ADMIN_SUCCESS,
  };
};

export const updateArtOfTheWeekFailure = (error) => {
  return {
    type: UPDATE_ART_OF_THE_WEEK_ADMIN_FAILURE,
    payload: error,
  };
};

export const updateArtOfTheWeek = (data) => {
  return (dispatch) => {
    dispatch(updatePopularArtistRequest());
    API.put(apiName, path, {
      ...myInit,
      body: data,
    })
      .then((response) => {
        dispatch(updatePopularArtistSuccess());
      })
      .catch((error) => {
        dispatch(updatePopularArtistFailure(error.response.data.message));
      });
  };
};
