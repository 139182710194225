import React from "react";
import { Modal, Tooltip } from "antd";
import QRCode from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import { closeQrModal } from "../../Redux";
import logo from "./Logo3.png";
import { CopyOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";

const QrCodeModal = ({ title }) => {
  const dispatch = useDispatch();
  const { qrCodeModal } = useSelector((state) => ({
    qrCodeModal: state.qrCodeModal,
  }));
  const downloadQR = () => {
    html2canvas(document.getElementById("art-work-qrcode")).then(function (
      canvas
    ) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${
        qrCodeModal.data.fileName || "image"
      }(QR Code).png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };
  return (
    <Modal
      title={qrCodeModal.data?.title || "QR Code"}
      width="350px"
      footer={null}
      visible={qrCodeModal.visible}
      onCancel={() => dispatch(closeQrModal())}
    >
      <div id="art-work-qrcode">
        <QRCode
          value={qrCodeModal.data.link}
          size={306}
          fgColor="#323588"
          cor
          imageSettings={{
            src: logo,
            excavate: true,
            width: 40,
            height: 40,
          }}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "calc(100% - 35px) 35px",
            alignItems: "center",
            gap: 0,
            justifyContent: "center",
            width: "100%",
            backgroundColor: "#f0f2f5",
          }}
        >
          <p
            style={{
              margin: 0,
              padding: "3px",
              fontSize: "12px",
            }}
          >
            {qrCodeModal.data.link}
          </p>
          <Tooltip title="Copy to clipboard">
            <CopyOutlined
              style={{ fontSize: "30px", padding: "3px", cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(qrCodeModal.data.link);
                toast.success("Copied to clipbard");
              }}
            />
          </Tooltip>
        </div>
      </div>
      <p
        className="text-center my-2"
        style={{
          color: "#323588",
          cursor: "pointer",
        }}
        onClick={downloadQR}
      >
        Download QR Code
      </p>
    </Modal>
  );
};

export default QrCodeModal;
