import {
  INCREASE_STEP,
  DECREASE_STEP,
  OPEN_EDIT_PROFILE_MODAL,
  CLOSE_EDIT_PROFILE_MODAL,
} from "./type";

export const increaseStep = () => ({ type: INCREASE_STEP });

export const decreaseStep = () => ({ type: DECREASE_STEP });

export const openEditProfileModal = () => ({ type: OPEN_EDIT_PROFILE_MODAL });

export const closeEditProfileModal = () => ({ type: CLOSE_EDIT_PROFILE_MODAL });
