import {
  FETCH_POPULARARTIST_REQUEST,
  FETCH_POPULARARTIST_SUCCESS,
  FETCH_POPULARARTIST_FAILURE,
 
} from './type';

const initialState={
  loading: false,
  popularArtist : undefined,
  error  : '',
  success: ''
}

const reducer = (state = initialState , action)=>{
  switch(action.type){
    case FETCH_POPULARARTIST_REQUEST :
      return{
        ...state,
        loading: true
      }
    case FETCH_POPULARARTIST_SUCCESS :
    return{
      loading: false,
      popularArtist: action.payload,
      error: '',
      success: action.success
    }
    case FETCH_POPULARARTIST_FAILURE :
      return{
        loading: false,
        popularArtist: undefined,
        error: action.payload

    }
    default: return state
  }
}
export default reducer;