export const FETCH_ALL_ARTIST_ADMIN_REQUEST = "FETCH_ALL_ARTIST_ADMIN_REQUEST";
export const FETCH_ALL_ARTIST_ADMIN_SUCCESS = "FETCH_ALL_ARTIST_ADMIN_SUCCESS";
export const FETCH_ALL_ARTIST_ADMIN_FAILURE = "FETCH_ALL_ARTIST_ADMIN_FAILURE";

export const UPDATE_POPULAR_ARTIST_ADMIN_REQUEST =
  "UPDATE_POPULAR_ARTIST_ADMIN_REQUEST";
export const UPDATE_POPULAR_ARTIST_ADMIN_SUCCESS =
  "UPDATE_POPULAR_ARTIST_ADMIN_SUCCESS";
export const UPDATE_POPULAR_ARTIST_ADMIN_FAILURE =
  "UPDATE_POPULAR_ARTIST_ADMIN_FAILURE";

export const GET_CONFIG_REQUEST = "GET_CONFIG_REQUEST";
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";

export const GET_ART_OF_THE_WEEK_CONFIG_REQUEST =
  "GET_ART_OF_THE_WEEK_CONFIG_REQUEST";
export const GET_ART_OF_THE_WEEK_CONFIG_SUCCESS =
  "GET_ART_OF_THE_WEEK_CONFIG_SUCCESS";
export const GET_ART_OF_THE_WEEK_CONFIG_FAILURE =
  "GET_ART_OF_THE_WEEK_CONFIG_FAILURE";

export const GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_REQUEST =
  "GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_REQUEST";
export const GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_SUCCESS =
  "GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_SUCCESS";
export const GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_FAILURE =
  "GET_HIGHLIGHTS_OF_THE_WEEK_CONFIG_FAILURE";

export const GET_PHOTO_GALLERY_CONFIG_REQUEST =
  "GET_PHOTO_GALLERY_CONFIG_REQUEST";
export const GET_PHOTO_GALLERY_CONFIG_SUCCESS =
  "GET_PHOTO_GALLERY_CONFIG_SUCCESS";
export const GET_PHOTO_GALLERY_CONFIG_FAILURE =
  "GET_PHOTO_GALLERY_CONFIG_FAILURE";

export const UPDATE_ART_OF_THE_WEEK_ADMIN_REQUEST =
  "UPDATE_ART_OF_THE_WEEK_ADMIN_REQUEST";
export const UPDATE_ART_OF_THE_WEEK_ADMIN_SUCCESS =
  "UPDATE_ART_OF_THE_WEEK_ADMIN_SUCCESS";
export const UPDATE_ART_OF_THE_WEEK_ADMIN_FAILURE =
  "UPDATE_ART_OF_THE_WEEK_ADMIN_FAILURE";
