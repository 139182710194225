import {
  FETCH_ALL_ARTIST_REQUEST,
  FETCH_ALL_ARTIST_SUCCESS,
  FETCH_ALL_ARTIST_FAILURE,
  FETCH_ARTIST_DROPDOWN_REQUEST,
  FETCH_ARTIST_DROPDOWN_SUCCESS,
  FETCH_ARTIST_DROPDOWN_FAILURE,
} from "./type";

const initialState = {
  loading: false,
  artist: undefined,
  fetchArtistDropdown: {
    loading: false,
    data: [],
  },
  error: "",
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_ARTIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_ARTIST_SUCCESS:
      return {
        ...state,
        loading: false,
        artist: action.payload,
        error: "",
        success: action.success,
      };
    case FETCH_ALL_ARTIST_FAILURE:
      return {
        ...state,
        loading: false,
        artist: undefined,
        error: action.payload,
      };
    case FETCH_ARTIST_DROPDOWN_REQUEST:
      return {
        ...state,
        fetchArtistDropdown: {
          loading: false,
          data: [],
        },
      };
    case FETCH_ARTIST_DROPDOWN_SUCCESS:
      return {
        ...state,
        fetchArtistDropdown: {
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_ARTIST_DROPDOWN_FAILURE:
      return {
        ...state,
        fetchArtistDropdown: {
          loading: false,
          data: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
