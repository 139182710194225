import {
  OPEN_SUBMIT_ART_WORK_MODAL,
  CLOSE_SUBMIT_ART_WORK_MODAL,
} from "./type";

export const openSubmitArtWorkModal = () => ({
  type: OPEN_SUBMIT_ART_WORK_MODAL,
});

export const closeSubmitArtWorkModal = () => ({
  type: CLOSE_SUBMIT_ART_WORK_MODAL,
});
