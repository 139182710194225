import { CLOSE_QR_MODAL, OPEN_QR_MODAL } from "./type";

export const openQrModal = (data) => ({
  type: OPEN_QR_MODAL,
  payload: data,
});

export const closeQrModal = () => ({
  type: CLOSE_QR_MODAL,
});
