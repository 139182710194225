import React from "react";
import { Card, Popconfirm } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { deleteArtWork, setSelectedArtWork } from "../../../Redux";
import { connect } from "react-redux";
import store from "../../../Redux/store";
import "./styles.css";
import { Storage } from "aws-amplify";
import { openUploadArtModal } from "../../../Redux/UploadArtModal/action";

const { Meta } = Card;

const SubmitArtCard = ({ data, imgSrc, deleteArtWork, openModal }) => {
  const deleteArt = () => {
    Storage.list(`artist_work/${data._id}`, { level: "protected" })
      .then((result) => {
        const promise = [];

        result.forEach((element) => {
          promise.push(Storage.remove(element.key, { level: "protected" }));
        });

        if (promise) {
          Promise.all(promise).then((res) => {
            deleteArtWork(data._id);
          });
        } else {
          deleteArtWork(data._id);
        }
      })
      .catch((err) => {});
    // Storage.remove(`artist_work/${data._id}/*`, { level: 'protected' })
    //   .then ( (result) => {
    //
    //   })
    //   .catch( (err) => {
    //
    //   });
  };

  return (
    <Card
      hoverable
      style={{ width: 240, margin: 10 }}
      cover={
        <img
          height="230px"
          alt="example"
          src={imgSrc ? imgSrc : "img/svg/no-photo.png"}
        />
      }
      actions={[
        <Popconfirm
          title="Are you sure to delete this Art Work?"
          onConfirm={deleteArt}
          okText="Yes"
          cancelText="No"
        >
          <DeleteFilled key="Delete" />
        </Popconfirm>,
        <EditFilled
          onClick={() => {
            store.dispatch(setSelectedArtWork(data));
            openModal();
          }}
        />,
      ]}
    >
      <div style={{ height: "50px" }}>
        <Meta title={data.title} />
        <p
          style={{
            whiteSpace: "nowrap",
            width: "150px",
            overflow: "hidden",
            textOverflow: " ellipsis",
          }}
        >
          {data.description}
        </p>
      </div>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteArtWork: (id) => dispatch(deleteArtWork(id)),
    openModal: () => dispatch(openUploadArtModal()),
    // getArtWork: () => dispatch(fetchArtWork())
  };
};

export default connect(null, mapDispatchToProps)(SubmitArtCard);
