import { API } from "aws-amplify";
import {
  FETCH_INSTAGRAM_POSTS_FAILURE,
  FETCH_INSTAGRAM_POSTS_REQUEST,
  FETCH_INSTAGRAM_POSTS_SUCCESS,
  LINK_INSTAGRAM_FAILURE,
  LINK_INSTAGRAM_REQUEST,
  LINK_INSTAGRAM_SUCCESS,
  UNLINK_INSTAGRAM_FAILURE,
  UNLINK_INSTAGRAM_REQUEST,
  UNLINK_INSTAGRAM_SUCCESS,
} from "./type";
import config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import { fetchArtist, fetchArtistSuccess } from "../Artist/action";

const apiName = "backend-api";
const path = "/api/artists/";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const linkInstagram = (authCode, pushToProfile) => (dispatch) => {
  dispatch({ type: LINK_INSTAGRAM_REQUEST });
  API.post(apiName, `${path}/linkInstagramAccount`, {
    ...myInit,
    body: {
      code: authCode,
    },
  })
    .then((res) => {
      toast.success("Instagram account linked");
      dispatch({ type: LINK_INSTAGRAM_SUCCESS });
      dispatch(fetchArtistSuccess(res.data));
      pushToProfile && pushToProfile();
    })
    .catch((error) => {
      toast.error("Failed. Please try again.");
      dispatch({ type: LINK_INSTAGRAM_FAILURE });
      pushToProfile && pushToProfile();
    });
};

export const fetchInstagramPosts = (authCode, onSuccess) => (dispatch) => {
  dispatch({ type: FETCH_INSTAGRAM_POSTS_REQUEST });
  let body = {};
  if (authCode) {
    body.code = authCode;
  }
  API.post(apiName, `${path}/fetchInstagramPosts`, {
    ...myInit,
    body,
  })
    .then((res) => {
      toast.success("Posts synced");
      dispatch({ type: FETCH_INSTAGRAM_POSTS_SUCCESS });
      dispatch(fetchArtistSuccess(res.data));
      onSuccess && onSuccess();
    })
    .catch((error) => {
      toast.error("Please, Try again later");
      dispatch({ type: FETCH_INSTAGRAM_POSTS_FAILURE });
    });
};

export const unlinkInstagram = () => (dispatch) => {
  dispatch({ type: UNLINK_INSTAGRAM_REQUEST });
  API.post(apiName, `${path}/unlinkInstagramAccount`)
    .then((res) => {
      toast.success("Account Unlinked");
      dispatch({ type: UNLINK_INSTAGRAM_SUCCESS });
      dispatch(fetchArtist());
    })
    .catch((error) => {
      dispatch({ type: UNLINK_INSTAGRAM_FAILURE });
      toast.error("Something went wrong, Please try again after some time.");
    });
};
