const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    restricted: false,
    public: true,
  },
  {
    path: ["/profile/instagram/verify"],
    exact: true,
    component: "Instagram",
    restricted: false,
    public: true,
  },
  {
    path: ["/profile"],
    exact: false,
    component: "Profile",
    restricted: false,
    public: false,
  },
  {
    path: ["/artist/:userName"],
    exact: false,
    component: "Profile",
    restricted: false,
    public: true,
  },
  {
    path: ["/art-work/:id"],
    exact: false,
    component: "ArtWork",
    restricted: false,
    public: true,
  },
  {
    path: ["/all-artist"],
    exact: false,
    component: "Artist",
    restricted: false,
    public: true,
  },
  {
    path: ["/art-collection"],
    exact: false,
    component: "AllArtWork",
    restricted: false,
    public: true,
  },
  {
    path: ["/all-categories"],
    exact: false,
    component: "AllCategories",
    restricted: false,
    public: true,
  },
  {
    path: ["/art-space/:username"],
    exact: true,
    component: "AlternateSpace",
    restricted: false,
    public: true,
  },
  {
    path: ["/admin"],
    exact: false,
    component: "Admin",
    restricted: false,
    public: false,
    role: ["admin", "curator"],
  },
  {
    path: ["/art-space"],
    exact: true,
    component: "AllAlternateSpace",
    restricted: false,
    public: true,
  },
  {
    path: ["/faqs"],
    exact: true,
    component: "FAQs",
    restricted: false,
    public: true,
  },
];

export default routes;
