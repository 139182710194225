import axios from "axios";
import { API, Auth } from "aws-amplify";
import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CLEAR_STORE,
} from "./type";
import { createArtist } from "../Artist/action";
import { ToastContainer, toast } from "react-toastify";

const apiName = "backend-api";
const path = "/api/users";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const fetchUser = () => {
  return (dispatch) => {
    dispatch(fetchUserRequest());
    API.get(apiName, path, myInit)
      .then((response) => {
        // response.data is the users
        const user = response.data;
        dispatch(fetchUserSuccess(user));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchUserFailure(error.response.data.message));
        toast.error("Unsuccessful");
      });
  };
};

export const createUser = (data) => {
  myInit.body = data;
  return (dispatch) => {
    dispatch(postUserRequest());
    API.post(apiName, path, myInit)
      .then((response) => {
        // response.data is the users
        // const user = response.data
        dispatch(postUserSuccess([]));

        dispatch(createArtist({ userId: response.data.id }));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(postUserFailure(error.response.data.message));
      });
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(deleteUserRequest());
    API.del(apiName, path + `/${id}`)
      .then((response) => {
        // response.data is the users
        const user = response.data.data;
        dispatch(deleteUserSuccess(user));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(deleteUserFailure(error.response.data.message));
      });
  };
};

export const updateUser = (data, onSuccess, onError) => {
  myInit.body = data;
  return (dispatch) => {
    dispatch(updateUserRequest());
    API.put(apiName, path, myInit)
      .then((response) => {
        // response.data is the users
        const user = response.data;
        dispatch(updateUserSuccess(user));
        onSuccess && onSuccess();
        // toast.success("Successful")
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(updateUserFailure(error.response.data.message));
        toast.error("Unsuccessful");
        onError && onError();
      });
  };
};

//post request
export const postUserRequest = () => {
  return {
    type: POST_USER_REQUEST,
  };
};

export const postUserSuccess = (user) => {
  return {
    type: POST_USER_SUCCESS,
    payload: user,
    success: "succesful",
  };
};

export const postUserFailure = (error) => {
  return {
    type: POST_USER_FAILURE,
    payload: error,
  };
};

//DELETE REQUEST

export const deleteUserRequest = () => {
  return {
    type: DELETE_USER_REQUEST,
  };
};

export const deleteUserSuccess = (user) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: user,
    success: "succesfully deleted",
  };
};

export const deleteUserFailure = (error) => {
  return {
    type: DELETE_USER_FAILURE,
    payload: error,
  };
};

//update rquest

export const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

export const updateUserSuccess = (user) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user,
    success: "succesfully UPDATED",
  };
};

export const updateUserFailure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
};

// get request
export const fetchUserRequest = () => {
  return {
    type: FETCH_USER_REQUEST,
  };
};

export const fetchUserSuccess = (user) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: user,
    success: "succesful",
  };
};

export const fetchUserFailure = (error) => {
  return {
    type: FETCH_USER_FAILURE,
    payload: error,
  };
};

export const clearUser = () => {
  return {
    type: CLEAR_STORE,
  };
};
