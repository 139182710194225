import { CLOSE_REGISTRATION_MODAL, OPEN_REGISTRATION_MODAL } from "./type";

export const openRegistrationModal = (type) => {
  return {
    type: OPEN_REGISTRATION_MODAL,
    payload: type,
  };
};

export const closeRegistrationModal = () => {
  return {
    type: CLOSE_REGISTRATION_MODAL,
  };
};
