import { OPEN_QR_MODAL, CLOSE_QR_MODAL } from "./type";

const initialState = {
  visible: false,
  data: null,
};

const qrCodeModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_QR_MODAL:
      return {
        ...state,
        visible: true,
        data: action.payload,
      };
    case CLOSE_QR_MODAL:
      return {
        ...state,
        visible: false,
        data: null,
      };
    default:
      return state;
  }
};

export default qrCodeModalReducer;
