import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import QrCodeModal from "../../components/QrCodeModal";
import Registration from "../Registration";
import SubmitArtWork from "../SubmitArtWork";
import ArtWorkModal from "../../components/ArtWorkUpload";
import PromotionModal from "../../components/PromotionModal";
import ArtStyleModal from "../../components/ArtStylesModal.js";

const Modals = () => {
  const {
    registrationModal,
    submitArtWorkModal,
    qrCodeModal,
    artWorkModal,
    artStyleModal,
  } = useSelector((state) => ({
    registrationModal: state.registrationModal.visible,
    submitArtWorkModal: state.submitArtWorkModal.visible,
    qrCodeModal: state.qrCodeModal.visible,
    artWorkModal: state.artWorkModal,
    artStyleModal: state.artStyleModal,
  }));
  const [promotionModal, setPromotionModal] = useState({ visible: false });
  // useEffect(() => {
  //   setTimeout(() => {
  //     setPromotionModal({ visible: true });
  //   }, 3000);
  // }, []);
  return (
    <>
      {registrationModal && <Registration />}
      {submitArtWorkModal && <SubmitArtWork />}
      {qrCodeModal && <QrCodeModal />}
      {artWorkModal.visible && <ArtWorkModal />}
      {promotionModal.visible && (
        <PromotionModal
          promotionModal={promotionModal}
          setPromotionModal={setPromotionModal}
        />
      )}
      {artStyleModal.visible && <ArtStyleModal />}
    </>
  );
};

export default Modals;
