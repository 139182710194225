import React, { useEffect, useState } from "react";
import { Upload, Col, Modal, Tooltip, Button, Card } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CropModal from "./CropModal";

const { Meta } = Card;

const { Dragger } = Upload;

const ArtUpload = ({
  artWorkData,
  setShowArtWorkInformation,
  setFetchingArtistWork,
}) => {
  const [fileList, setFileList] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const fetchArtistWork = () => {
    setFetchingArtistWork(true);
    Storage.list(
      `artist_work/${
        artWorkData?.selectedArtWork?.id
          ? artWorkData?.selectedArtWork?.id
          : artWorkData?.selectedArtWork?._id
      }`,
      { level: "protected" }
    )
      .then((result) => {
        const promise = [];
        const data = [];
        result.forEach((item) =>
          promise.push(Storage.get(item.key, { level: "protected" }))
        );

        Promise.all(promise).then((res) => {
          res.forEach((element, index) => {
            data.push({ uid: index, url: element });
          });
          setFileList(data);
          setFetchingArtistWork(false);
        });
      })
      .catch((err) => {
        setFetchingArtistWork(false);
      });
  };

  useEffect(() => {
    setShowArtWorkInformation(fileList && fileList.length > 0);
  }, [fileList]);

  useEffect(() => {
    fetchArtistWork();
  }, [artWorkData]);

  const discardImage = () => {
    setFileList((oldValue) => [...oldValue.slice(0, oldValue.length - 1)]);
  };

  const uploadImage = async (
    options,
    image,
    aspectRatio,
    onUploadSuccess,
    onUploadError
  ) => {
    const { onSuccess, onError, onProgress } = options;
    Storage.put(
      `artist_work/${
        artWorkData?.selectedArtWork?.id
          ? artWorkData?.selectedArtWork?.id
          : artWorkData?.selectedArtWork?._id
      }/${Date.now()}/${image.name}`,
      image,
      {
        level: "protected",
        contentType: "image",
        progressCallback(progress) {
          onProgress(
            {
              percent: Math.round((progress.loaded / progress.total) * 100),
            },
            image
          );
        },
      }
    )
      .then((result) => {
        onSuccess("Ok");
        fetchArtistWork();
        toast.success("Art Work Uploaded");
        onUploadSuccess && onUploadSuccess();
      })
      .catch((err) => {
        onError({ err });
        onUploadError && onUploadError();
        toast.error("Unsuccessful");
      });
  };

  const handlePreview = async (file) => {
    if (file.url) {
      setImageUrl(file.url);
    } else {
      setImageUrl(file.thumbUrl);
    }
    setModalVisible(true);
  };

  const handleOnChange = ({ file, fileList, event }) => {
    setFileList(fileList);
  };

  const handleCancel = () => setModalVisible(false);

  const [cropModal, setCropModal] = useState({
    visible: false,
    data: null,
  });

  const onRemove = async (file) => {
    const url = decodeURIComponent(file.url);
    const key = url.substring(url.indexOf("artist_work"), url.indexOf("?"));
    await Storage.remove(key, { level: "protected" });
    setFileList((oldValue) => [
      ...oldValue.filter((item) => item.url !== file.url),
    ]);
    toast.success("Art Work Deleted");
  };

  return (
    <div className="pl-2 pr-2 welcome-screen-container">
      <Col span={24} className="mb-20">
        <h1>Art Information</h1>
      </Col>
      <div className="mt-2">
        <Tooltip title="Upload Art">
          <Dragger
            multiple={false}
            accept="image/*"
            customRequest={(options) => {
              setCropModal({
                visible: true,
                data: options,
              });
            }}
            onPreview={handlePreview}
            listType="picture-card"
            fileList={fileList}
            onRemove={onRemove}
          >
            <div
              style={{
                // minHeight: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </div>
          </Dragger>
        </Tooltip>
      </div>
      {cropModal.visible && (
        <CropModal
          cropModal={cropModal}
          setCropModal={setCropModal}
          onCompleteCrop={uploadImage}
          discardImage={discardImage}
          setFileList={setFileList}
        />
      )}
      <Modal visible={modalVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={imageUrl} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    artWorkData: state.artWork,
  };
};
export default connect(mapStateToProps)(ArtUpload);
