import { API } from "aws-amplify";
import {
  FETCH_ARTSTYLE_REQUEST,
  FETCH_ARTSTYLE_SUCCESS,
  FETCH_ARTSTYLE_FAILURE,
  POST_ARTSTYLE_REQUEST,
  POST_ARTSTYLE_SUCCESS,
  POST_ARTSTYLE_FAILURE,
  DELETE_ARTSTYLE_REQUEST,
  DELETE_ARTSTYLE_SUCCESS,
  DELETE_ARTSTYLE_FAILURE,
  UPDATE_ARTSTYLE_REQUEST,
  UPDATE_ARTSTYLE_SUCCESS,
  UPDATE_ARTSTYLE_FAILURE,
  CLEAR_STORE,
} from "./type";
import { ToastContainer, toast } from "react-toastify";

const apiName = "backend-api";
const path = "/api/art-styles";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const fetchArtStyles = () => {
  return (dispatch) => {
    dispatch(fetchArtStyleRequest());
    API.get(apiName, path, myInit)
      .then((response) => {
        // Add your code here

        dispatch(fetchArtStyleSuccess(response.data));
        // toast.success("Successful")
      })
      .catch((error) => {
        dispatch(fetchArtStyleFailure(error.response.data.message));
        toast.error("Unsuccessful");
      });
  };
};
export const createArtStyle = (data) => {
  myInit.body = data;
  return (dispatch) => {
    dispatch(postArtStyleRequest());
    API.post(apiName, path, myInit)
      .then((response) => {
        // response.data is the users
        const user = response.data.data;
        dispatch(postArtStyleSuccess(user));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(postArtStyleFailure(error.response.data.message));
      });
  };
};

export const deleteArtStyle = (id) => {
  return (dispatch) => {
    dispatch(deleteArtStyleRequest());
    API.del(apiName, path + `/${id}`)
      .then((response) => {
        // response.data is the users
        const user = response.data.data;
        dispatch(deleteArtStyleSuccess(user));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(deleteArtStyleFailure(error.response.data.message));
      });
  };
};

export const updateArtStyle = (id, data) => {
  myInit.body = data;
  return (dispatch) => {
    dispatch(updateArtStyleRequest());
    API.put(apiName, path + `/${id}`, myInit)
      .then((response) => {
        // response.data is the users
        const user = response.data.data;
        dispatch(updateArtStyleSuccess(user));
        toast.success("Art Style Updated Successfully.");
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(updateArtStyleFailure(error.response.data.message));
      });
  };
};

export const fetchArtStyleRequest = () => {
  return {
    type: FETCH_ARTSTYLE_REQUEST,
  };
};

export const fetchArtStyleSuccess = (artStyle) => {
  return {
    type: FETCH_ARTSTYLE_SUCCESS,
    payload: artStyle,
  };
};

export const fetchArtStyleFailure = (error) => {
  return {
    type: FETCH_ARTSTYLE_FAILURE,
    payload: error,
  };
};
export const postArtStyleRequest = () => {
  return {
    type: POST_ARTSTYLE_REQUEST,
  };
};

export const postArtStyleSuccess = (user) => {
  return {
    type: POST_ARTSTYLE_SUCCESS,
    payload: user,
    success: "succesful",
  };
};

export const postArtStyleFailure = (error) => {
  return {
    type: POST_ARTSTYLE_FAILURE,
    payload: error,
  };
};

//DELETE REQUEST

export const deleteArtStyleRequest = () => {
  return {
    type: DELETE_ARTSTYLE_REQUEST,
  };
};

export const deleteArtStyleSuccess = (user) => {
  return {
    type: DELETE_ARTSTYLE_SUCCESS,
    payload: user,
    success: "succesfully deleted",
  };
};

export const deleteArtStyleFailure = (error) => {
  return {
    type: DELETE_ARTSTYLE_FAILURE,
    payload: error,
  };
};

//update rquest

export const updateArtStyleRequest = () => {
  return {
    type: UPDATE_ARTSTYLE_REQUEST,
  };
};

export const updateArtStyleSuccess = (user) => {
  return {
    type: UPDATE_ARTSTYLE_SUCCESS,
    payload: user,
    success: "succesfully UPDATED",
  };
};

export const updateArtStyleFailure = (error) => {
  return {
    type: UPDATE_ARTSTYLE_FAILURE,
    payload: error,
  };
};
export const clearArtStyle = () => {
  return {
    type: CLEAR_STORE,
  };
};
