import styled from "styled-components";

export const DomainDiv = styled.div`
  display: flex;
  flex-wrap: ${({ wrap }) => `${wrap ? "wrap" : "nowrap"}`};
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  max-heigth: 300px;
  overflow-x: ${({ wrap }) => `${wrap ? "none" : "auto"}`};
  overflow-y: auto;
  padding: 10px;
  transition: all 0.3s ease-in-out;
`;

export const DomainItem = styled.div`
  padding: 5px 15px;
  font-size: 1rem;
  flex-grow: 1;
  cursor: pointer;
  background-color: ${({ type }) =>
    type === "domain"
      ? "#E23825"
      : type === "category"
      ? "#323588"
      : type === "speciality"
      ? "#F9D42D"
      : "gray"};
  color: ${(props) => `${props.type === "speciality" ? "black" : "white"}`};
  font-weigth: bold;
  white-space: ${(props) => `${props.wrapText ? "wrap" : "nowrap"}`};
  border: 3px solid ${(props) => `${props.selected ? "black" : "transparent"}`};
  text-align: center;
  transition: all 0.2s ease-in-out;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  .count {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    border-radius: 50%;
    border: 2px solid white;
    color: white;
    background-color: black;
    padding: 5px;
  }

  @media (hover: hover) {
    :hover {
      transform: scale(1.1);
      .count {
        top: -13px;
        right: -13px;
      }
    }
  }
`;

export const SelectedDiv = styled.div`
  display: grid;
  padding: 5px 10px;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const SelectedDivItem = styled.div`
  background-color: black;
  color: white;
  padding: 5px 10px;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  
  }
`;

export const ArtStylesDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: auto;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .service-box {
    position: relative;
    width: 150px;
    height: 150px;
    padding: 20px;
    border: 3px solid;
    border-radius: 30%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    text-align: center;
  }

  p {
    text-transform: uppercase;
    margin: 0;
  }

  p.category {
    font-size: 1rem;
  }
  p.speciality {
    font-size: 0.75rem;
  }
  .service-box.first {
    border-color: #6c6ce5;
    :hover {
      background-color: #6c6ce5;
    }
  }
  .service-box.second {
    border-color: #f9d74c;
    :hover {
      background-color: #f9d74c;
    }
  }
  .service-box.third {
    border-color: #f97b8b;
    :hover {
      background-color: #f97b8b;
    }
  }
  .service-box.first.selected {
    background-color: #6c6ce5;
  }
  .service-box.second.selected {
    background-color: #f9d74c;
  }
  .service-box.third.selected {
    background-color: #f97b8b;
  }
  .remove {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0px;
    right: 0px;
    border-radius: 50%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    cursor: pointer;
    border: 2px solid white;
    :before {
      content: "x";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 5px;
    }
  }
`;

export const SelectedStyleCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  align-items: center;
  justiy-content: center;
  margin: 15px 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
