import { API } from "aws-amplify";
import {
  FETCH_ALL_ARTIST_REQUEST,
  FETCH_ALL_ARTIST_SUCCESS,
  FETCH_ALL_ARTIST_FAILURE,
  CLEAR_STORE,
  FETCH_ARTIST_DROPDOWN_REQUEST,
  FETCH_ARTIST_DROPDOWN_SUCCESS,
  FETCH_ARTIST_DROPDOWN_FAILURE,
} from "./type";

const apiName = "backend-api";
const path = "/api/artists";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const fetchAllArtists = (data, sort) => {
  myInit["queryStringParameters"] = {};
  if (data.domain) myInit.queryStringParameters["domain"] = data.domain;
  if (data.category) myInit.queryStringParameters["category"] = data.category;
  if (data.specialist)
    myInit.queryStringParameters["specialist"] = data.specialist;
  myInit.queryStringParameters["sort"] = sort;
  return (dispatch) => {
    dispatch(fetchAllArtistRequest());
    API.get(apiName, path + `/getFilterData`, myInit)
      .then((response) => {
        // response.data is the users
        const artist = response.data;
        dispatch(fetchAllArtistSuccess(artist));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchAllArtistFailure(error.response.data.message));
      });
  };
};

export const fetchAllArtistRequest = () => {
  return {
    type: FETCH_ALL_ARTIST_REQUEST,
  };
};

export const fetchAllArtistSuccess = (artist) => {
  return {
    type: FETCH_ALL_ARTIST_SUCCESS,
    payload: artist,
  };
};

export const fetchAllArtistFailure = (error) => {
  return {
    type: FETCH_ALL_ARTIST_FAILURE,
    payload: error,
  };
};

export const fetchArtistDropdown = (artistList) => (dispatch) => {
  dispatch({ type: FETCH_ARTIST_DROPDOWN_REQUEST });
  const body = { ...myInit };
  if (artistList) {
    body.queryStringParameters = { artistList, restrictedAccess: true };
  }
  API.get(apiName, `${path}/dropdown`, body)
    .then((res) => {
      dispatch({
        type: FETCH_ARTIST_DROPDOWN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ARTIST_DROPDOWN_FAILURE,
      });
    });
};
