import {
  BgColorsOutlined,
  CalendarOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  FileOutlined,
  FormOutlined,
  GroupOutlined,
  MediumOutlined,
} from "@ant-design/icons";
import { Form, Modal, Select, Spin, Button, Input, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArtUpload from "./Upload";
import TextArea from "antd/lib/input/TextArea";
import { toast } from "react-toastify";
import { fetchPublicArtist } from "../../Redux/PublicProfile/action";
import {
  closeArtWorkModal,
  updateArtWork,
  fetchArtWork,
  getArtWorkTags,
  openArtStyleModal,
  fetchArtStyles,
} from "../../Redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { isAuthorizedAdminOrCurator } from "../../helper";
import { useAppContext } from "../../common/ContextLib";

const ArtWorkModal = () => {
  const dispatch = useDispatch();
  const { group } = useAppContext();
  const { artWorkModal, artist, tags, artStylesData } = useSelector(
    (state) => ({
      artWorkModal: state.artWorkModal,
      artist: state.artWorkModal.isAdmin
        ? state.publicArtist.publicArtist
        : state.artist.artist,
      tags: state.artWork.artWorkTags,
      artStylesData: state.artStyle,
    })
  );

  const [showArtWorkInformation, setShowArtWorkInformation] = useState(false);
  const [fetchingArtistWork, setFetchingArtistWork] = useState(false);
  const [selectedArtStyle, setSelectedArtStyle] = useState(
    artWorkModal.data.artStyleId?._id
  );
  const [selectedArtStyleData, setSelectedArtStyleData] = useState();

  useEffect(() => {
    dispatch(getArtWorkTags());
  }, []);

  useEffect(() => {
    dispatch(fetchArtStyles());
  }, []);

  useEffect(() => {
    if (artStylesData && artStylesData.artStyle && selectedArtStyle) {
      const data = artStylesData.artStyle.find(
        (item) => item.id === selectedArtStyle
      );

      if (data) {
        setSelectedArtStyleData(data);
      }
    }
  }, [artStylesData, selectedArtStyle]);

  return (
    <Modal
      visible={artWorkModal.visible}
      onCancel={() => {
        dispatch(closeArtWorkModal());
      }}
      title={
        artWorkModal.action === "edit" ? "Update Art Work" : "Upload Art Work"
      }
      width={window.innerWidth <= 768 ? "95%" : "55%"}
      footer={null}
    >
      <Formik
        validationSchema={Yup.object({
          artStyleId: Yup.string().required(),
          title: Yup.string().trim().required(),
          cost: Yup.string()
            .trim()
            .when("available", {
              is: true,
              then: Yup.string()
                .trim()
                .required("asked price is a required field"),
              otherwise: Yup.string()
                .trim()
                .when("copy", {
                  is: true,
                  then: Yup.string()
                    .trim()
                    .required("asked price is a required field"),
                  otherwise: Yup.string().trim(),
                }),
            }),
          tags: Yup.array()
            .required("material / technique is a required field")
            .min(1, "select alteast one material / technique"),
          available: Yup.boolean()
            .nullable()
            .required("availability status is required"),
          copy: Yup.boolean()
            .nullable()
            .required("availability type is required"),
          durationToPrepare: Yup.number().when("copy", {
            is: true,
            then: Yup.number()
              .max(30, "duration should not be more than 30 days")
              .required("duration is required"),
          }),
        })}
        onSubmit={(values) => {
          if (!selectedArtStyle) {
            return toast.error("Select Art Style");
          }
          dispatch(
            updateArtWork(
              artWorkModal.data.id || artWorkModal.data._id,
              {
                ...values,
                artistId: artist.id,
                artStyleId: selectedArtStyle,
                status: "submitted",
              },
              () => {
                if (artWorkModal.isAdmin) {
                  dispatch(fetchPublicArtist(artist.userId.userName));
                } else {
                  dispatch(fetchArtWork());
                }
                dispatch(closeArtWorkModal());
              },
              null,
              true
            )
          );
        }}
        initialValues={
          artWorkModal.action === "edit"
            ? {
                artistId: artWorkModal.data.artistId || "",
                artStyleId: artWorkModal.data.artStyleId._id,
                title: artWorkModal.data.title || "",
                description: artWorkModal.data.description || "",
                cost: artWorkModal.data.cost || "",
                available: artWorkModal.data.available,
                height: artWorkModal.data.height || "",
                width: artWorkModal.data.width || "",
                copy: artWorkModal.data.copy,
                tags: (artWorkModal.data.tags || []).map(
                  (tag) => tag._id || tag.id
                ),
                durationToPrepare: artWorkModal.data.durationToPrepare || "",
                medium: artWorkModal.data.medium || "",
                theme: artWorkModal.data.theme || "",
                material: artWorkModal.data.material || "",
              }
            : {
                artistId: artist.id,
                artStyleId: "",
                title: "",
                description: "",
                cost: "",
                available: null,
                height: "",
                width: "",
                copy: null,
                tags: [],
                durationToPrepare: "",
              }
        }
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          handleChange,
        }) => (
          <>
            <ArtUpload
              setShowArtWorkInformation={setShowArtWorkInformation}
              setFetchingArtistWork={setFetchingArtistWork}
              artWorkData={artWorkModal.data}
              isAdmin={artWorkModal.isAdmin}
            />
            <div
              style={{
                width: "100%",
                height: "50px",
                padding: "20px 10px",
                display: fetchingArtistWork ? "flex" : "none",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Spin spinning={fetchingArtistWork} />
            </div>
            <hr />
            {showArtWorkInformation && (
              <Form onFinish={handleSubmit} layout="vertical">
                <div
                  className="pl-2 pr-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Form.Item
                    help={
                      errors.artStyleId && touched.artStyleId
                        ? errors.artStyleId
                        : undefined
                    }
                    validateStatus={
                      errors.artStyleId && touched.artStyleId
                        ? "error"
                        : undefined
                    }
                    label="Art Style"
                  >
                    <div
                      className="d-flex justify-content-start align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Button
                        type="primary"
                        onClick={() => {
                          dispatch(
                            openArtStyleModal({
                              data: {
                                id: values.artStyleId,
                              },
                              fn: {
                                onArtStyleSelected: (id) => {
                                  setSelectedArtStyle(id);
                                  setFieldValue("artStyleId", id);
                                },
                              },
                            })
                          );
                        }}
                      >
                        Select Art Style
                      </Button>
                      {selectedArtStyleData && (
                        <p
                          style={{
                            fontWeight: "bold",
                            margin: 0,
                          }}
                        >
                          {[
                            selectedArtStyleData.domain,
                            selectedArtStyleData.category,
                            selectedArtStyleData.specialist,
                          ]
                            .filter((i) => !!i)
                            .join(" -> ")}
                        </p>
                      )}
                    </div>
                  </Form.Item>

                  <Row gutter={16}>
                    <Col md={8} xs={24}>
                      <Form.Item
                        label="Available for sale"
                        help={
                          touched.available && errors.available
                            ? errors.available
                            : undefined
                        }
                        validateStatus={
                          touched.available && errors.available
                            ? "error"
                            : undefined
                        }
                      >
                        <Select
                          value={values.available}
                          options={[
                            {
                              label: "Yes",
                              value: true,
                            },
                            {
                              label: "No",
                              value: false,
                            },
                          ]}
                          name="available"
                          onChange={(value) => {
                            setFieldValue("available", value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {typeof values.available === "boolean" &&
                      (values.available ? (
                        <Col md={8} xs={24}>
                          <Form.Item
                            help={
                              touched.copy && errors.copy
                                ? errors.copy
                                : undefined
                            }
                            validateStatus={
                              touched.copy && errors.copy ? "error" : undefined
                            }
                            label="Original or Copy"
                          >
                            <Select
                              options={[
                                {
                                  label: "Original",
                                  value: false,
                                },
                                {
                                  label: "Copy",
                                  value: true,
                                },
                              ]}
                              value={values.copy}
                              onChange={(value) => {
                                setFieldValue("copy", value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      ) : (
                        <Col md={8} xs={24}>
                          <Form.Item
                            help={
                              touched.copy && errors.copy
                                ? errors.copy
                                : undefined
                            }
                            validateStatus={
                              touched.copy && errors.copy ? "error" : undefined
                            }
                            label="Can make copy?"
                          >
                            <Select
                              options={[
                                {
                                  label: "Yes",
                                  value: true,
                                },
                                {
                                  label: "No",
                                  value: false,
                                },
                              ]}
                              value={values.copy}
                              onChange={(value) => {
                                setFieldValue("copy", value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    {values.copy && (
                      <Col md={8} xs={24}>
                        <Form.Item
                          help={
                            touched.durationToPrepare &&
                            errors.durationToPrepare
                              ? errors.durationToPrepare
                              : undefined
                          }
                          validateStatus={
                            touched.durationToPrepare &&
                            errors.durationToPrepare
                              ? "error"
                              : undefined
                          }
                          label="Days to prepare"
                        >
                          <Input
                            prefix={
                              <CalendarOutlined className="site-form-item-icon" />
                            }
                            type="Number"
                            name="durationToPrepare"
                            value={values.durationToPrepare}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>

                  <Row gutter={16}>
                    <Col md={12} xs={24}>
                      <Form.Item
                        help={
                          touched.title && errors.title
                            ? errors.title
                            : undefined
                        }
                        validateStatus={
                          touched.title && errors.title ? "error" : undefined
                        }
                        label="Title"
                      >
                        <Input
                          prefix={
                            <FormOutlined className="site-form-item-icon" />
                          }
                          value={values.title}
                          type="string"
                          name="title"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                      <Form.Item
                        help={
                          touched.cost && errors.cost ? errors.cost : undefined
                        }
                        validateStatus={
                          touched.cost && errors.cost ? "error" : undefined
                        }
                        label="Asked Price"
                      >
                        <Input
                          prefix="₹"
                          min={0}
                          type="number"
                          step="1"
                          value={values.cost}
                          name="cost"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        label="Material / Technique"
                        help={
                          touched.tags && errors.tags ? errors.tags : undefined
                        }
                        validateStatus={
                          touched.tags && errors.tags ? "error" : undefined
                        }
                      >
                        <Select
                          placeholder="Add material / technique"
                          loading={tags.loading}
                          mode="multiple"
                          allowClear
                          value={values.tags}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                          dropdownMatchSelectWidth
                          onChange={(value) => {
                            setFieldValue("tags", value);
                          }}
                        >
                          {tags.data.map((tag, index) => (
                            <Select.Option value={tag.id} key={index}>
                              {tag.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {}
                  {isAuthorizedAdminOrCurator(group, true) &&
                    artWorkModal.action === "edit" && (
                      <Row gutter={16}>
                        <Col md={8} xs={24} flex={"auto"}>
                          <Form.Item label="Medium">
                            <Input
                              prefix={
                                <MediumOutlined className="site-form-item-icon" />
                              }
                              type="string"
                              value={values.medium}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24} flex={"auto"}>
                          <Form.Item label="Theme">
                            <Input
                              prefix={
                                <BgColorsOutlined className="site-form-item-icon" />
                              }
                              type="string"
                              value={values.theme}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24} flex={"auto"}>
                          <Form.Item label="Material & other details">
                            <Input
                              prefix={
                                <GroupOutlined className="site-form-item-icon" />
                              }
                              type="string"
                              value={values.material}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  <Form.Item label="Dimension (In Inches)">
                    <Row gutter={16}>
                      <Col md={8} xs={24}>
                        <Input
                          prefix={
                            <ColumnHeightOutlined className="site-form-item-icon" />
                          }
                          min={0}
                          type="Number"
                          placeholder="Height"
                          name="height"
                          value={values.height}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={8} xs={24}>
                        <Input
                          prefix={
                            <ColumnWidthOutlined className="site-form-item-icon" />
                          }
                          min={0}
                          type="Number"
                          placeholder="Width"
                          name="width"
                          value={values.width}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Row gutter={16}>
                    <Col xs={24} flex="auto">
                      <Form.Item label="Description">
                        <TextArea
                          prefix={
                            <FileOutlined className="site-form-item-icon" />
                          }
                          row={4}
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Row>
                </div>
              </Form>
            )}
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ArtWorkModal;
