import React from "react";
import { Menu, Select } from "antd";
import "./styles.css";

const Navigation = ({ step }) => {
  const { Option, OptGroup } = Select;
  const menu = [
    "Upload Information",
    // "Art Resource",
    "Art Information",
    "Thank You",
  ];
  return (
    <>
      <Menu
        className="app-left-navigation desktop-navigation"
        selectedKeys={[menu[step]]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        inlineCollapsed={false}
      >
        {menu.map((item) => (
          <Menu.Item key={item} disabled>
            {item}
          </Menu.Item>
        ))}
      </Menu>

      <div className="mobile-navigation">
        <Select
          labelInValue
          className="mb-20"
          key={step}
          defaultValue={{ value: menu[step] }}
          style={{ width: "100%" }}
        >
          {menu.map((item) => (
            <Option key={item} value={item} disabled>
              {item}
            </Option>
          ))}
        </Select>
      </div>
    </>
  );
};

export default Navigation;
