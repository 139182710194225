import {
  OPEN_SUBMIT_ART_WORK_MODAL,
  CLOSE_SUBMIT_ART_WORK_MODAL,
} from "./type";

const initialState = {
  visible: false,
};

const submitArtWorkReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SUBMIT_ART_WORK_MODAL:
      return {
        ...state,
        visible: true,
      };
    case CLOSE_SUBMIT_ART_WORK_MODAL:
      return {
        ...state,
        visible: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default submitArtWorkReducer;
