export const getName = (artist) =>
  artist.firstName || artist.lastName
    ? `${[artist.firstName, artist.lastName]
        .filter((item) => !!item)
        .join(" ")}`
    : artist.username;

export const getArtWorkData = (data) => {
  if (!data) {
    return [];
  }
  const temp = [];
  data.forEach((style) => {
    style.data.forEach((artWork) => {
      temp.push(artWork);
    });
  });
  return temp;
};

export const isAuthorizedAdminOrCurator = (group, condition = undefined) => {
  return (
    group === "admin" ||
    (typeof condition === "undefined"
      ? group === "curator"
      : group === "curator" && condition)
  );
};
