import { OPEN_ART_STYLE_MODAL, CLOSE_ART_STYLE_MODAL } from "./type";

const initialState = {
  visible: false,
  data: {
    id: null,
  },
  fn: {
    onArtStyleSelect: null,
  },
};

export default function ArtStyleReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_ART_STYLE_MODAL:
      return {
        ...state,
        visible: true,
        ...action.payload,
      };
    case CLOSE_ART_STYLE_MODAL:
      return {
        ...state,
        visible: false,
        data: {
          id: null,
        },
        fn: {
          onArtStyleSelect: null,
        },
      };
    default:
      return {
        ...state,
      };
  }
}
