import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
// import './result.css';

import Router from "./router";
import i18n from "./translation";
import * as serviceWorker from "./serviceWorker";
import { Amplify, API, Auth, Hub, Storage } from "aws-amplify";
import config from "./config";
import { useEffect, useState } from "react";
import { AppContext } from "./common/ContextLib/index";
import store from "./Redux/store";
import { Provider } from "react-redux";
import { clearUser, fetchUser } from "./Redux/User/action";
import { clearArtist, fetchArtist } from "./Redux/Artist/action";
import {
  clearArtStyle,
  clearArtWork,
  fetchArtStyles,
  fetchArtWork,
} from "./Redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modals from "./pages/Modals";

// store.subscribe(() =>console.log(store.get))

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    region: "ap-south-1",
    identityPoolId: "ap-south-1:8900d046-2da1-497a-b296-13ae762c41bb",
  },
  Storage: {
    bucket: "05-202011-kalacube-artist", //REQUIRED -  Amazon S3 bucket name
    identityPoolId: "ap-south-1:8900d046-2da1-497a-b296-13ae762c41bb",
    region: "ap-south-1", //OPTIONAL -  Amazon service region
  },
});

const App = () => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const [accessToken, setAccessToken] = useState();
  const [group, setGroup] = useState("user");

  API.configure({
    endpoints: [
      {
        name: "backend-api",
        endpoint: "https://api.kalacube.com",
        // endpoint: "http://localhost:5000",
        service: "lambda",
        region: "ap-south-1",
        custom_header: async () => {
          return { Authorization: accessToken };
        },
      },
    ],
  });

  Storage.configure();
  useEffect(() => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          onLoad();
          break;
        case "signOut":
          setGroup("user");
          break;
      }
    });
    onLoad();
    store.dispatch(fetchArtStyles());
  }, []);

  const onLoad = async () => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      userHasAuthenticated(true);
      setAccessToken(token);

      const groups = (await Auth.currentSession()).getAccessToken().payload[
        "cognito:groups"
      ];

      if (groups && groups.length > 0) {
        setGroup(groups.includes("admin") ? "admin" : groups[0]);
      }

      // store.dispatch(fetchUser());
      store.dispatch(fetchArtist());
      // store.dispatch(fetchArtStyles());
    } catch (e) {
      if (e !== "No current user") {
        toast.error(e, { position: "top-center" });
      }
      store.dispatch(clearArtist());
      store.dispatch(clearUser());
      // store.dispatch(clearArtStyle());
      store.dispatch(clearArtWork());
    }

    setIsAuthenticating(false);
  };

  return (
    <BrowserRouter>
      {!isAuthenticating && (
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <AppContext.Provider
              value={{
                isAuthenticated,
                userHasAuthenticated,
                setAccessToken,
                group,
              }}
            >
              <Modals />
              <Router />
            </AppContext.Provider>
          </Provider>
        </I18nextProvider>
      )}
      <ToastContainer />
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
