import { API, Storage } from "aws-amplify";
import {
  FETCH_ARTWORK_REQUEST,
  FETCH_ARTWORK_SUCCESS,
  FETCH_ARTWORK_FAILURE,
  POST_ARTWORK_REQUEST,
  POST_ARTWORK_SUCCESS,
  POST_ARTWORK_FAILURE,
  UPDATE_ARTWORK_REQUEST,
  UPDATE_ARTWORK_SUCCESS,
  UPDATE_ARTWORK_FAILURE,
  DELETE_ARTWORK_REQUEST,
  DELETE_ARTWORK_SUCCESS,
  DELETE_ARTWORK_FAILURE,
  USERID_ARTWORK_REQUEST,
  USERID_ARTWORK_SUCCESS,
  USERID_ARTWORK_FAILURE,
  CLEAR_STORE,
  SET_SELECTED_ART_WORK,
  PUBLIC_ARTWORK_REQUEST,
  PUBLIC_ARTWORK_SUCCESS,
  PUBLIC_ARTWORK_FAILURE,
  FETCH_RELATED_ARTWORK_REQUEST,
  FETCH_RELATED_ARTWORK_SUCCESS,
  FETCH_ALL_ARTWORK_REQUEST,
  FETCH_ALL_ARTWORK_SUCCESS,
  FETCH_ALL_ARTWORK_FAILURE,
  GET_ALL_ARTWORK_CHUNK_REQUEST,
  GET_ALL_ARTWORK_CHUNK_SUCCESS,
  GET_ALL_ARTWORK_CHUNK_FAILURE,
  GET_ALL_ARTWORK_CHUNK_RESET,
  GET_RECENT_ARTWORK_REQUEST,
  GET_RECENT_ARTWORK_SUCCESS,
  GET_RECENT_ARTWORK_FAILURE,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  CREATE_TAG_REQUEST,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
} from "./type";
import { ToastContainer, toast } from "react-toastify";
import { FETCH_ALL_ARTIST_ADMIN_FAILURE } from "../Admin/type";
import CONSTANTS from "../../constants";

const apiName = "backend-api";
const path = "/api/art-works";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const fetchArtWork = () => {
  return (dispatch) => {
    dispatch(fetchArtWorkRequest());
    API.get(apiName, path, myInit)
      .then((response) => {
        // response.data is the users
        const artWork = response.data;
        dispatch(fetchArtWorkSuccess(artWork));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(
          fetchArtWorkFailure(
            error.response?.data?.message || error.message || error
          )
        );
      });
  };
};

export const createArtWork = (data, onSuccess, onError) => {
  myInit.body = data;
  return (dispatch) => {
    dispatch(postArtWorkRequest());
    API.post(apiName, path, myInit)
      .then((response) => {
        // console.log(response)
        // response.data is the users
        const artWork = response.data;
        // console.log("in here", artWork);
        // dispatch(postArtWorkSuccess(artWork));
        // toast.success("Art Work Created Successfuly")

        // dispatch(fetchArtWork());
        onSuccess && onSuccess(artWork);
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(postArtWorkFailure(error.response?.data?.message));
        toast.error("Unsuccessful");

        onError && onError();
      });
  };
};

export const deleteArtWork = (id, identityId, onSuccess, onError, isAdmin) => {
  myInit.queryStringParameters = { id: id };
  return (dispatch) => {
    dispatch(deleteArtWorkRequest());
    API.del(apiName, path, myInit)
      .then((response) => {
        Storage.list(`artist_work/${id}`, { level: "protected", identityId })
          .then(async (result) => {
            const promise = [];

            result.forEach((element) => {
              promise.push(
                Storage.remove(element.key, { level: "protected", identityId })
              );
            });

            await Promise.all(promise);
          })
          .catch((err) => {});
        // response.data is the users
        const artWork = response.data;
        if (!isAdmin) {
          dispatch(deleteArtWorkSuccess(artWork));
          dispatch(fetchArtWork());
        } else {
          dispatch(deleteArtWorkSuccess({}));
        }

        onSuccess && onSuccess();
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(deleteArtWorkFailure(error.response.data.message));
        onError && onError();
      });
  };
};

export const updateArtWork = (
  id,
  data,
  onSuccess,
  onError,
  isAdmin = false
) => {
  myInit.body = data;
  myInit.queryStringParameters = { id: id };
  return (dispatch) => {
    dispatch(updateArtWorkRequest());
    API.put(apiName, path, myInit)
      .then((response) => {
        // response.data is the users

        const artWork = response.data;
        if (!isAdmin) {
          dispatch(updateArtWorkSuccess(artWork));
          dispatch(fetchArtWork());
        } else {
          dispatch(updateArtWorkSuccess());
        }
        toast.success("Art Work Created/ Updated Successfully.");
        onSuccess && onSuccess();
      })
      .catch((error) => {
        // error.message is the error message
        if (!isAdmin) {
          dispatch(updateArtWorkFailure(error.response.data.message));
        }
        toast.error("Unsuccessful");
        onError && onError();
      });
  };
};

export const fetchArtistByUserid = (userid) => {
  return (dispatch) => {
    dispatch(fetchArtWorkByUserIdRequest());
    API.get(apiName, path + `/${userid}`, myInit)
      .then((response) => {
        // response.data is the users
        const artist = response.data;
        dispatch(fetchArtWorkByUserIdSuccess(artist));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchArtWorkByUserIdFailure(error.response.data.message));
      });
  };
};

export const fetchPublicArtWork = (id) => {
  myInit.queryStringParameters = { id: id };
  return (dispatch) => {
    dispatch(fetchPublicArtWorkRequest());
    API.get(apiName, `${path}/getArtWork`, myInit)
      .then((response) => {
        // response.data is the users
        const artist = response.data;
        dispatch(fetchPublicArtWorkSuccess(artist));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchPublicArtWorkFailure(error.response.data.message));
      });
  };
};

export const fetchArtWorkRequest = () => {
  return {
    type: FETCH_ARTWORK_REQUEST,
  };
};

export const fetchArtWorkSuccess = (artWork) => {
  return {
    type: FETCH_ARTWORK_SUCCESS,
    payload: artWork,
    success: "succesful",
  };
};

export const fetchArtWorkFailure = (error) => {
  return {
    type: FETCH_ARTWORK_FAILURE,
    payload: error,
  };
};
export const postArtWorkRequest = () => {
  return {
    type: POST_ARTWORK_REQUEST,
  };
};

export const postArtWorkSuccess = (artWork) => {
  return {
    type: POST_ARTWORK_SUCCESS,
    payload: artWork,
    success: "succesful",
  };
};

export const postArtWorkFailure = (error) => {
  return {
    type: POST_ARTWORK_FAILURE,
    payload: error,
  };
};

//DELETE REQUEST

export const deleteArtWorkRequest = () => {
  return {
    type: DELETE_ARTWORK_REQUEST,
  };
};

export const deleteArtWorkSuccess = (artWork) => {
  return {
    type: DELETE_ARTWORK_SUCCESS,
    payload: artWork,
    success: "succesfully deleted",
  };
};

export const deleteArtWorkFailure = (error) => {
  return {
    type: DELETE_ARTWORK_FAILURE,
    payload: error,
  };
};

//update rquest

export const updateArtWorkRequest = () => {
  return {
    type: UPDATE_ARTWORK_REQUEST,
  };
};

export const updateArtWorkSuccess = (artWork) => {
  return {
    type: UPDATE_ARTWORK_SUCCESS,
    payload: artWork,
    success: "succesfully UPDATED",
  };
};

export const updateArtWorkFailure = (error) => {
  return {
    type: UPDATE_ARTWORK_FAILURE,
    payload: error,
  };
};
export const fetchArtWorkByUserIdRequest = () => {
  return {
    type: USERID_ARTWORK_REQUEST,
  };
};

export const fetchArtWorkByUserIdSuccess = (artist) => {
  return {
    type: USERID_ARTWORK_SUCCESS,
    payload: artist,
  };
};

export const fetchArtWorkByUserIdFailure = (error) => {
  return {
    type: USERID_ARTWORK_FAILURE,
    payload: error,
  };
};

export const fetchPublicArtWorkRequest = () => {
  return {
    type: PUBLIC_ARTWORK_REQUEST,
  };
};

export const fetchPublicArtWorkSuccess = (artWork) => {
  return {
    type: PUBLIC_ARTWORK_SUCCESS,
    payload: artWork,
  };
};

export const fetchPublicArtWorkFailure = (error) => {
  return {
    type: PUBLIC_ARTWORK_FAILURE,
    payload: error,
  };
};

export const clearArtWork = () => {
  return {
    type: CLEAR_STORE,
  };
};

export const setSelectedArtWork = (selectedArtWork) => {
  return {
    type: SET_SELECTED_ART_WORK,
    payload: selectedArtWork,
  };
};

export const fetchRelatedArtWork =
  (filter, onSuccess, onError) => (dispatch) => {
    dispatch({
      type: FETCH_RELATED_ARTWORK_REQUEST,
    });
    myInit["queryStringParameters"] = filter;
    API.get(apiName, `${path}/getallartwork`, myInit)
      .then((res) => {
        if (res.data.length < 6) {
          delete myInit["queryStringParameters"]["specialist"];
          API.get(apiName, `${path}/getallartwork`, myInit)
            .then((res) => {
              dispatch({
                type: FETCH_RELATED_ARTWORK_SUCCESS,
                payload: res.data,
              });
              onSuccess && onSuccess(res.data);
              return;
            })
            .catch((error) => {
              dispatch({ type: FETCH_ALL_ARTIST_ADMIN_FAILURE });
              onError && onError();
              return;
            });
        }
        dispatch({ type: FETCH_RELATED_ARTWORK_SUCCESS, payload: res.data });
        onSuccess && onSuccess(res.data);
      })
      .catch((error) => {
        dispatch({ type: FETCH_ALL_ARTIST_ADMIN_FAILURE });
        onError && onError();
      });
  };

export const fetchAllArtWork = (filter, onSuccess, onError) => (dispatch) => {
  dispatch({ type: FETCH_ALL_ARTWORK_REQUEST });
  myInit["queryStringParameters"] = filter || {};
  const promise = API.get(apiName, `${path}/getallartwork`, myInit);
  promise
    .then((res) => {
      dispatch({
        type: FETCH_ALL_ARTWORK_SUCCESS,
        payload: res.data,
      });
      dispatch(getAllArtWorkChunk(res.data, 0));
      // onSuccess && onSuccess(res.data);
    })
    .catch((error) => {
      if (error.message === "REQUEST_ABORTED") {
        return;
      }

      dispatch({ type: FETCH_ALL_ARTWORK_FAILURE });
      onError && onError();
    });
  return () => {
    API.cancel(promise, "REQUEST_ABORTED");
  };
};

export const getAllArtWorkChunk = (data, page) => async (dispatch) => {
  const requestTimeStamp = Date.now();
  dispatch({
    type: GET_ALL_ARTWORK_CHUNK_REQUEST,
    payload: { timestamp: requestTimeStamp, append: page > 0 },
  });
  const selected = data.slice(
    page * CONSTANTS.ARTWORKS_PAGE_SIZE,
    page * CONSTANTS.ARTWORKS_PAGE_SIZE + CONSTANTS.ARTWORKS_PAGE_SIZE
  );
  const artWorks = [];

  try {
    for (let item of selected) {
      const response = await Storage.list(`artist_work/${item.id}`, {
        level: "protected",
        identityId: item.artistId.userId.identityId,
      });
      item["image"] = [];
      if (response.length > 0) {
        const image = await Storage.get(response[0].key, {
          level: "protected",
          identityId: item.artistId.userId.identityId,
        });
        item["image"].push(image);
      }
      artWorks.push(item);
    }

    dispatch({
      type: GET_ALL_ARTWORK_CHUNK_SUCCESS,
      payload: {
        timestamp: requestTimeStamp,
        append: page > 0,
        data: artWorks,
      },
    });
  } catch (error) {
    dispatch({ type: GET_ALL_ARTWORK_CHUNK_FAILURE });
  }
};

export const getRecentArtWork = () => (dispatch) => {
  dispatch({ type: GET_RECENT_ARTWORK_REQUEST });
  API.get(apiName, `${path}/getRecentArtWork`)
    .then(async (res) => {
      const recent = [];
      for (let artWork of res.data) {
        const response = await Storage.list(`artist_work/${artWork.id}`, {
          level: "protected",
          identityId: artWork.artistId.userId.identityId,
        });
        artWork["image"] = [];
        if (response.length > 0) {
          const image = await Storage.get(response[0].key, {
            level: "protected",
            identityId: artWork.artistId.userId.identityId,
          });
          artWork["image"].push(image);
        }
        recent.push(artWork);
      }
      dispatch({ type: GET_RECENT_ARTWORK_SUCCESS, payload: recent });
    })
    .catch((error) => {
      toast.error(error.response?.data?.message || error.message || error);
      dispatch({ type: GET_RECENT_ARTWORK_FAILURE });
    });
};

export const getArtWorkTags = () => (dispatch) => {
  dispatch({ type: FETCH_TAGS_REQUEST });
  API.get(apiName, `${path}/tags`)
    .then((res) => {
      dispatch({ type: FETCH_TAGS_SUCCESS, payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: FETCH_TAGS_FAILURE });
    });
};

export const createTag = (tag, onSuccess, onError) => (dispatch) => {
  dispatch({ type: CREATE_TAG_REQUEST });
  API.post(apiName, `${path}/tags`, {
    body: {
      name: tag,
    },
  })
    .then((res) => {
      dispatch({ type: CREATE_TAG_SUCCESS });
      toast.success("Tag added");
      onSuccess && onSuccess();
    })
    .catch((error) => {
      dispatch({ type: CREATE_TAG_FAILURE });
      toast.error(error.response?.data?.message || error.message || error);
      onError && onError();
    });
};

export const removeTag = (id, onSuccess, onError) => (dispatch) => {
  dispatch({ type: DELETE_TAG_REQUEST });
  API.del(apiName, `${path}/tags/${id}`)
    .then((res) => {
      dispatch({ type: DELETE_TAG_SUCCESS });
      toast.success("Tag removed");
      onSuccess && onSuccess();
    })
    .catch((error) => {
      dispatch({ type: DELETE_TAG_FAILURE });
      toast.error(error.response?.data?.message || error.message || error);
      onError && onError();
    });
};
