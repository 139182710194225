import { Link } from "react-router-dom";
import styled from "styled-components";

export const LoginBackground = styled.div`
  display: flex;
  justify-content: space-around;
  height: fit-content;
  text-align: center;
`;

export const LoginLeft = styled.div`
  background: #9f9f9f;
  width: 50%;
  clip-path: polygon(0 0, 100% 0, 90% 83%, 0% 90%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 35px 10%;
  gap: 1rem;

  h1 {
    font-size: 2rem;
    color: white;
    margin: 0;
  }
  p {
    color: white;
    margin: 3px;
    width: 75%;
  }
  button {
    width: 120px;
    background: rgba(50, 53, 136, 255);
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    font-weight: bolder;
    outline: none;
    border: none;
    height: 40px;
    margin: 0;
  }
  button:hover {
    color: rgb(255, 255, 255);
    border: 1px solid rgba(249, 212, 41, 255);
    background-color: rgba(249, 212, 41, 255);
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  padding-top: 1rem;
`;

export const ButtonDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const LoginRight = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 43%;
  gap: 10px;

  .switch-button {
    display: none;
  }

  img {
    margin: 0px 10px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    background-color: #000000ba;
    clip-path: polygon(0 0, 100% 0, 90% 95%, 0 98%);
    padding: 20px 35px 10%;

    .switch-button {
      display: inline-block;
    }

    h1,
    p {
      color: white;
    }
  }
`;
