import { Button, Card, Col, Modal, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import * as S from "../ProfileComponents/ProfileEdit/ArtistStyles/styles";
import { closeArtStyleModal, fetchArtStyles } from "../../Redux";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

const ArtStyleModal = () => {
  const dispatch = useDispatch();
  const { artStylesData, artStyleModal } = useSelector((state) => ({
    artStylesData: state.artStyle,
    artStyleModal: state.artStyleModal,
  }));

  const [artStyles, setArtStyles] = useState([]);
  const [artStyleDomain, setArtStyleDomain] = useState(null);
  const [artStyleCategory, setArtStyleCategory] = useState(null);
  const [artStyleSpecialist, setArtStyleSpecialist] = useState();
  const [artStyleCategories, setArtStyleCategories] = useState([]);
  const [artStyleSpecialists, setArtStyleSpecialists] = useState([]);
  const [selectedArtStyle, setSelectedArtStyle] = useState(
    artStyleModal.data.id
  );

  useEffect(() => {
    if (
      artStyles &&
      artStylesData &&
      artStylesData.artStyle &&
      artStyleModal.data.id
    ) {
      const arr = [];
      const index = artStylesData.artStyle.findIndex(
        (item) => item.id === artStyleModal.data.id
      );
      setArtStyleDomain(artStylesData.artStyle[index].domain);
      setArtStyleCategory(artStylesData.artStyle[index].category);
      const categories =
        find(artStyles, { value: artStylesData.artStyle[index].domain })
          ?.children || [];
      setArtStyleCategories(categories);
      setArtStyleSpecialists(
        find(categories, {
          value: artStylesData.artStyle[index].category,
        })?.children || []
      );
      setArtStyleSpecialist(artStylesData.artStyle[index].specialist);
    }
  }, [artStylesData, artStylesData.artStyle, artStyles]);

  useEffect(() => {
    dispatch(fetchArtStyles());
  }, []);

  useEffect(() => {
    if (!artStylesData.artStyle) {
      return;
    }
    const groupByCategory = groupBy(artStylesData.artStyle, "domain");
    Object.keys(groupByCategory).map(
      (item) =>
        (groupByCategory[item] = groupBy(groupByCategory[item], "category"))
    );
    setArtStyles(totreeNode(groupByCategory));
  }, [artStylesData]);

  const totreeNode = (data) =>
    Object.keys(data).map((item) => {
      if (Array.isArray(data[item])) {
        return {
          title: item,
          value: item,
          disabled: true,
          children: data[item].map((val) => {
            return { title: val.specialist, value: val.id };
          }),
        };
      } else {
        return {
          title: item,
          value: item,
          disabled: true,
          children: totreeNode(data[item]),
        };
      }
    });

  var groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const find = (data, condition) => {
    const filter = data.filter((item) => {
      return (
        Object.keys(condition).filter((key) => item[key] !== condition[key])
          .length === 0
      );
    });
    return filter.length > 0 ? filter[0] : null;
  };

  return (
    <Modal
      visible={artStyleModal.visible}
      onCancel={() => {
        dispatch(closeArtStyleModal());
      }}
      title="Select Art Style"
      width={window.innerWidth <= 768 ? "95%" : "55%"}
      footer={null}
    >
      <Row>
        <Card className="w-100">
          <Row>
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              {[artStyleDomain, artStyleCategory, artStyleSpecialist]
                .filter((i) => !!i)
                .join(" -> ")}
            </p>
          </Row>
          <Row align="middle" className="p-1">
            <Col md={4} sm={5}>
              <p className="m-0">Domain</p>
            </Col>
            <Col md={20} sm={19}>
              <S.DomainDiv>
                {artStyles.map((domain, index) => (
                  <Col md={10}>
                    <Zoom key={index}>
                      <S.DomainItem
                        type="domain"
                        selected={artStyleDomain === domain.value}
                        onClick={() => {
                          if (artStyleDomain !== domain.value) {
                            setArtStyleDomain(domain.value);
                            setArtStyleCategories(
                              find(artStyles, { value: domain.value })
                                ?.children || []
                            );
                            setArtStyleSpecialists([]);
                            setArtStyleCategory("");
                            setArtStyleSpecialist("");
                            setSelectedArtStyle();
                          }
                        }}
                      >
                        {domain.title}
                      </S.DomainItem>
                    </Zoom>
                  </Col>
                ))}
              </S.DomainDiv>
            </Col>
          </Row>
          {artStyleCategories.length > 0 && (
            <Row align="middle" className="p-1">
              <Col md={4} sm={5}>
                <p className="m-0">Category</p>
              </Col>
              <Col md={20} sm={19}>
                <S.DomainDiv>
                  {artStyleCategories.map((category) => (
                    <Zoom key={category.title}>
                      <S.DomainItem
                        type="category"
                        selected={category.value === artStyleCategory}
                        onClick={() => {
                          if (artStyleCategory !== category.value) {
                            setArtStyleCategory(category.value);
                            setArtStyleSpecialists(
                              find(artStyleCategories, {
                                value: category.value,
                              })?.children || []
                            );
                            setArtStyleSpecialist("");
                            setSelectedArtStyle();
                          }
                        }}
                      >
                        {category.title}
                      </S.DomainItem>
                    </Zoom>
                  ))}
                </S.DomainDiv>
              </Col>
            </Row>
          )}
          <Fade big when={artStyleSpecialists.length > 0}>
            <Row
              align="middle"
              style={{
                padding: "5px",
              }}
            >
              <Col md={4} sm={5}>
                <p className="m-0">Speciality</p>
              </Col>
              <Col md={20} sm={19}>
                <Row
                  gutter={[16, 16]}
                  style={{
                    maxHeight: "220px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {artStyleSpecialists.map((specialist) => (
                    <Col md={8} sm={12} xs={12}>
                      <Zoom key={specialist.title}>
                        <S.DomainItem
                          type="speciality"
                          wrapText
                          selected={artStyleSpecialist === specialist.title}
                          onClick={() => {
                            setArtStyleSpecialist(specialist.title);
                            setSelectedArtStyle(specialist.value);
                          }}
                        >
                          {specialist.title}
                        </S.DomainItem>
                      </Zoom>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Fade>
        </Card>
      </Row>
      <Row
        align="middle"
        justify="center"
        style={{
          margin: "15px 0",
        }}
      >
        <Button
          type="primary"
          size="large"
          disabled={!selectedArtStyle}
          onClick={() => {
            artStyleModal.fn.onArtStyleSelected &&
              artStyleModal.fn.onArtStyleSelected(selectedArtStyle);
            toast.success("Art Style Selected");
            dispatch(closeArtStyleModal());
          }}
        >
          Apply
        </Button>
      </Row>
    </Modal>
  );
};

export default ArtStyleModal;
