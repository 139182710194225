import {
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
} from "./type";
import { API } from "aws-amplify";

const apiName = "backend-api";
const path = "/api/users";
const myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const getUserDetails = (username, onSuccess, onError) => (dispatch) => {
  dispatch({ type: FETCH_USER_DETAILS_REQUEST });
  const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
    username
  );
  if (!isEmail) {
    dispatch({ type: FETCH_USER_DETAILS_SUCCESS, payload: username });
    return onSuccess && onSuccess(username);
  }
  API.get(apiName, `${path}/getUsername`, {
    ...myInit,
    queryStringParameters: {
      email: username,
    },
  })
    .then((res) => {
      dispatch({ type: FETCH_USER_DETAILS_SUCCESS, payload: res.data });
      if (res.data.length === 0) {
        return onError && onError("Incorrect username or password.");
      }
      onSuccess && onSuccess(res.data[0].userName);
    })
    .catch((error) => {
      dispatch({ type: FETCH_USER_DETAILS_FAILURE });
      onError &&
        onError(error.response?.data?.message || error.message || error);
    });
};
